import { DateTime } from 'luxon'
import { environment } from '../../environments/environment'

export class Time {

    public hours: number
    public minutes: number

    constructor(time: string | null, hours: number | null, minutes: number | null) {
        if (time !== null) {
            const components = time.split(':')
            this.hours = Number(components[ 0 ])
            this.minutes = Number(components[ 1 ])
        } else {
            this.hours = hours ?? 0
            this.minutes = minutes ?? 0
        }
    }

    equals(other: Time): boolean {
        return this.hours === other.hours &&
            this.minutes === other.minutes
    }

    equalsTimeOfDate(date: Date): boolean {
        return this.hours === date.getHours() &&
            this.minutes === date.getMinutes()
    }

    isBeforeOrEqual(other: Time): boolean {
        return this.compare(other) <= 0
    }

    isAfter(other: Time): boolean {
        return this.compare(other) > 0
    }

    isAfterOrEqual(other: Time): boolean {
        return this.compare(other) >= 0
    }

    isBefore(other: Time): boolean {
        return this.compare(other) < 0
    }

    dateTimeOnDate(date: Date): Date {
        return DateTime.fromJSDate(date)
            .setZone(environment.assumedVenueTimeZone)
            .set({
                hour: this.hours,
                minute: this.minutes,
                second: 0,
                millisecond: 0,
            })
            .toJSDate()
    }

    compare(other: Time): number {
        if (this.hours < other.hours) {
            return -1
        } else if (this.hours > other.hours) {
            return 1
        } else {
            if (this.minutes < other.minutes) {
                return -1
            } else if (this.minutes > other.minutes) {
                return 1
            } else {
                return 0
            }
        }
    }

    toString(): string {
        const hours = this.hours.toString().padStart(2, '0')
        const minutes = this.minutes.toString().padStart(2, '0')
        return `${hours}:${minutes}`
    }
}

export function toDateTimeRange(
    start: Time, end: Time, date: Date
): [Date, Date] {
    const endDateTime = end.dateTimeOnDate(date)
    if (end.isBefore(start)) {
        endDateTime.setDate(endDateTime.getDate() + 1)
    }
    return [start.dateTimeOnDate(date), endDateTime]
}
