import { BookingStatusType } from '@app/domain/BookingStatus'
import { Directive, ElementRef, Inject, Input, OnChanges } from '@angular/core'

enum BookingStatusClass {
    Requested = 'requested',
    Pending = 'pending-payment',
    Booked = 'booked',
    NoShow = 'no-show',
    Cancelled = 'cancelled',
    Waiting = 'waiting',
    PartiallySeated = 'partially-seated',
    Seated = 'seated',
    Finished = 'finished',
    Rejected = 'rejected',
}

@Directive({
    selector: '[appBookingStatus]',
    standalone: true,
})
export class BookingStatusDirective implements OnChanges {

    @Input() statusType!: BookingStatusType

    constructor(
        @Inject(ElementRef) private element: ElementRef
    ) { }

    ngOnChanges() {
        this.element.nativeElement.classList
            .remove(...Object.values(BookingStatusClass))
        this.element.nativeElement.classList
            .add(this.makePartySizeClassName())
    }

    private makePartySizeClassName() {
        switch (this.statusType) {
        case BookingStatusType.Requested:
            return BookingStatusClass.Requested
        case BookingStatusType.PendingPayment:
            return BookingStatusClass.Pending
        case BookingStatusType.Booked:
            return BookingStatusClass.Booked
        case BookingStatusType.NoShow:
            return BookingStatusClass.NoShow
        case BookingStatusType.Cancelled:
            return BookingStatusClass.Cancelled
        case BookingStatusType.Waiting:
            return BookingStatusClass.Waiting
        case BookingStatusType.PartiallySeated:
            return BookingStatusClass.PartiallySeated
        case BookingStatusType.Seated:
            return BookingStatusClass.Seated
        case BookingStatusType.Finished:
            return BookingStatusClass.Finished
        case BookingStatusType.Rejected:
            return BookingStatusClass.Rejected
        }
    }
}
