import { DateComponentUnit } from './DateComponentUnit'

export class DateComponent {
    constructor(
        public unit: DateComponentUnit,
        public value: number
    ) { }

    static allDaysOfWeek(): DateComponent[] {
        return (new Array(7))
            .fill(0)
            .map((_, i) => new DateComponent(DateComponentUnit.DAY_OF_WEEK, i + 1))
    }

    representativeDate(): Date {
        const today = new Date()
        const date = new Date()
        switch (this.unit) {
        case DateComponentUnit.DAY_OF_WEEK:
            {
                const distance = this.value - today.getDay()
                date.setDate(today.getDate() + distance)
                return date
            }
        }
        return today
    }

    representativeDateFormat(): string {
        switch (this.unit) {
        case DateComponentUnit.DAY_OF_WEEK:
            return 'EEEE'
        }
        return 'full'
    }

    unitName(): string {
        return DateComponentUnit.name(this.unit)
    }
}
