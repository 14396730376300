import { DateTime } from 'luxon'
import { Time } from '@app/domain/Time'

export class BookingSlot {
    dateTime: Date

    constructor(dateTime: Date) {
        this.dateTime = dateTime
    }

    timeInTimeZone(timeZone: string): Time {
        const dateTime = DateTime.fromJSDate(this.dateTime)
            .setZone(timeZone)
        return new Time(null, dateTime.hour, dateTime.minute)
    }

    endDateTime(bookingInterval: number): Date {
        const endDateTime = new Date(this.dateTime)
        endDateTime.setMinutes(endDateTime.getMinutes() + bookingInterval)
        return endDateTime
    }

    toLocalISOFormattedDateTimeString(): string {
        const offsetMs = this.dateTime.getTimezoneOffset() * 60 * 1000
        const msLocal =  this.dateTime.getTime() - offsetMs
        const dateLocal = new Date(msLocal)
        const iso = dateLocal.toISOString()
        return iso
    }

    isOnTheHour(): boolean {
        return this.dateTime.getMinutes() === 0
    }

    closestEdgeToDateTime(dateTime: Date, bookingInterval: number): Date {
        const start = this.dateTime
        const end = this.endDateTime(bookingInterval)
        const startDiff = Math.abs(start.getTime() - dateTime.getTime())
        const endDiff = Math.abs(end.getTime() - dateTime.getTime())
        return startDiff < endDiff ? start : end
    }
}
