import { PartySizeDuration } from '@app/domain/PartySizeDuration'
import { ReasonDateRangeFilter } from '@app/domain/ReasonDateRangeFilter'
import { ReasonScheduleRule } from '@app/domain/ReasonScheduleRule'

export class BookingReason {

    constructor(
        public id: string,
        public displayName: string,
        public displayOrder: number,
        public description: string | null,
        public contributesToKitchenVelocity: boolean,
        public schedule: ReasonScheduleRule[],
        public dateRangeFilters: ReasonDateRangeFilter[],
        public areaBookingOrder: string[] | null,
        public bookingDurations: PartySizeDuration[],
        public diningInformation: string | null,
        public minimumPartySize: number | null,
        public linkExclusive: boolean
    ) { }

    addScheduleRule(rule: ReasonScheduleRule) {
        this.schedule.push(rule)
    }

    updateScheduleRule(rule: ReasonScheduleRule) {
        const index = this.schedule.findIndex(r => r.id === rule.id)
        this.schedule[index] = rule
    }

    removeScheduleRule(rule: ReasonScheduleRule) {
        const index = this.schedule.findIndex(r => r.id === rule.id)
        this.schedule.splice(index, 1)
    }

    addDateRangeFilter(filter: ReasonDateRangeFilter) {
        this.dateRangeFilters.push(filter)
    }

    updateDateRangeFilter(filter: ReasonDateRangeFilter) {
        const index = this.dateRangeFilters.findIndex(f => f.id === filter.id)
        this.dateRangeFilters[index] = filter
    }

    removeDateRangeFilter(filter: ReasonDateRangeFilter) {
        const index = this.dateRangeFilters.findIndex(f => f.id === filter.id)
        this.dateRangeFilters.splice(index, 1)
    }

    appliesToDate(date: Date): boolean {
        const dateInSchedule = this.schedule
            .some(rule => rule.appliesToDate(date))
        if (!dateInSchedule) {
            return false
        }
        const restrictedToDateRanges = this.dateRangeFilters.length > 0
        if (!restrictedToDateRanges) {
            return true
        }
        return this.dateRangeFilters.some(filter => filter.appliesToDate(date))
    }

    durationForPartySize(date: Date, partySize: number): number | null {
        if (!this.appliesToDate(date)) {
            return null
        }
        const reasonScheduleRuleDuration = this.schedule
            .map(rule => rule.durationForPartySize(date, partySize))
            .filter(duration => duration !== null)[0] || null
        if (reasonScheduleRuleDuration !== null) {
            return reasonScheduleRuleDuration
        }
        const largestDurationThatFits = this.bookingDurations
            .filter(duration => duration.partySize !== null)
            .filter(duration => duration.partySize! <= partySize)
            .sort((a, b) => b.partySize! - a.partySize!)[0] || null
        return largestDurationThatFits?.duration ?? null
    }
}
