import { BookingSlot } from '@app/domain/BookingSlot'
import { DateTime } from 'luxon'
import { ScheduleRule } from './ScheduleRule'
import { environment } from '../../environments/environment'
import { toDateTimeRange } from '@app/domain/Time'

export class Service {

    constructor(
        public id: string,
        public displayName: string,
        public rules: ScheduleRule[]
    ) { }

    get openRules(): ScheduleRule[] {
        return this.rules.filter(rule => rule.period.open)
    }

    get closeRules(): ScheduleRule[] {
        return this.rules.filter(rule => !rule.period.open)
    }

    ruleWithId(ruleId: string): ScheduleRule | null {
        const rule = this.rules.find(rule => rule.id === ruleId)
        return rule ?? null
    }

    addRule(rule: ScheduleRule) {
        this.rules.push(rule)
    }

    updateRule(rule: ScheduleRule) {
        this.rules = this.rules.map(existingRule => {
            if (existingRule.id === rule.id) {
                return rule
            }
            return existingRule
        })
    }

    removeRule(rule: ScheduleRule) {
        this.rules = this.rules.filter(existingRule => {
            return existingRule.id !== rule.id
        })
    }

    bookingSlotExtent(): [Date, Date] {
        const dateTimeRange = this.dateRangesExtent(
            _ => true,
            DateTime.now().setZone(environment.assumedVenueTimeZone).toJSDate(),
            0
        )
        if (dateTimeRange === null) {
            return [new Date(), new Date()]
        }
        return dateTimeRange
    }

    bookingSlotExtentForDate(date: Date): [Date, Date] {
        const dateTimeRange = this.dateRangesExtent(
            rule => rule.appliesToDate(date),
            date,
            0
        )
        if (dateTimeRange === null) {
            return [new Date(date), new Date(date)]
        }
        return dateTimeRange
    }

    hasOpenRuleAtDateTime(date: Date): boolean {
        return this.openRules.some(rule => rule.appliesToDateTime(date))
    }

    hasRuleThatAppliesToDate(date: Date): boolean {
        return this.rules.some(rule => rule.appliesToDate(date))
    }

    arePaymentsEnabledAtDateTime(date: Date): boolean {
        return this.openRules
            .filter(rule => rule.period.open)
            .filter(rule => rule.appliesToDateTime(date))
            .every(rule => rule.paymentsEnabled)
    }

    dateRangesExtentForDate(date: Date, bookingInterval: number): [Date, Date] | null {
        return this.dateRangesExtent(
            rule => rule.appliesToDate(date),
            date,
            bookingInterval
        )
    }

    private dateRangesExtent(
        filter: (rule: ScheduleRule) => boolean,
        date: Date,
        bookingInterval: number
    ): [Date, Date] | null {
        const periods = this.rules.map(rule => {
            return {
                isOpen: rule.period.open,
                applies: filter(rule),
                start: rule.period.start,
                end: rule.period.end,
            }
        })
        const openPeriods = periods
            .filter(period => period.applies && period.isOpen)
            .map(period => [period.start, period.end])
            .map(period => toDateTimeRange(period[0], period[1], date))
            .map(period => {
                const lastBookingSlot = new BookingSlot(period[1])
                const endDateTime = lastBookingSlot.endDateTime(bookingInterval)
                return [period[0], endDateTime] as [Date, Date]
            })
        const closedPeriods = periods
            .filter(period => period.applies && !period.isOpen)
            .map(period => [period.start, period.end])
            .map(period => toDateTimeRange(period[0], period[1], date))
        const sortedDates = [...openPeriods, ...closedPeriods]
            .flat()
            .sort((a, b) => a.getTime() - b.getTime())
        if (sortedDates.length === 0) {
            return null
        }
        return [sortedDates[0], sortedDates[sortedDates.length - 1]]
    }
}
