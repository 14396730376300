import { AddressDTO } from './AddressDTO'

export class CreateVenueRequestDTO {
    constructor(
        public id: string,
        public displayName: string,
        public phoneNumber: string | null,
        public address: AddressDTO,
        public maxPartySize: number,
        public timeZone: string,
        public areaId: string,
        public areaDisplayName: string,
        public bookingDuration: number,
        public bookingInterval: number,
        public tableTurnaroundInterval: number,
        public scheduleId: string | null
    ) { }
}
