export class VenueClosureDTO {

    constructor(
        public id: string,
        public start: string,
        public end: string,
        public tableIds: string[],
        public reasonIds: string[],
        public closes: boolean,
        public dateCreated: string
    ) { }
}
