import { SKUDTO } from './SKUDTO'

export class VoucherDTO {
    constructor(
        public id: string,
        public displayName: string,
        public description: string | null,
        public skus: SKUDTO[],
        public dateCreated: string,
        public dateUpdated: string
    ) { }
}
