import { DateTime } from 'luxon'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {

    transform(
        date: Date | null,
        timeZone: string,
        localeString?: Intl.DateTimeFormatOptions,
        format?: string
    ): string {
        if (!date) {
            return ''
        }
        const dateTime = DateTime.fromJSDate(date)
            .setZone(timeZone)
        if (localeString) {
            return dateTime.toLocaleString(localeString)
        }
        if (format) {
            return dateTime.toFormat(format)
        }
        return dateTime.toLocaleString(DateTime.DATETIME_FULL)
    }
}
