import { ApplicationRef, Injectable, NgZone } from '@angular/core'
import {
    BehaviorSubject,
    Observable,
    catchError,
    first,
    mergeWith,
    of,
    switchMap,
    debounceTime,
    throttleTime,
    Subscription,
    tap,
    ReplaySubject, Subject,
} from 'rxjs'
import { BookingService } from '@services/booking.service'
import { BookingStatusType } from '@app/domain/BookingStatus'
import { ContextService } from '@services/context.service'
import { EventService } from '@services/event.service'
import { EventType } from '@app/domain/Event'
import { Helper } from '@app/helpers/utilities/helper'
import { NumberPublisher } from '@app/domain/NumberPublisher'
import { Booking } from '@app/domain/Booking'
import { TokenRepositoryService } from '@services/token-repository.service'
import { AuthService } from '@services/auth.service'
import { DateTime } from 'luxon'

@Injectable({
    providedIn: 'root',
})
export class RequestedBookingsCountPublisher implements NumberPublisher {

    private number = new BehaviorSubject<number>(0)
    public number$ = this.number.asObservable()
    private refreshRateSeconds = 60 * 5
    private pollingSubscription: Subscription | null = null

    constructor(
        private appRef: ApplicationRef,
        private bookingService: BookingService,
        private contextService: ContextService,
        private eventService: EventService,
        private zone: NgZone
    ) { }

    startPollingForRequestedBookings() {
        if (this.pollingSubscription) {
            return
        }
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable))
        appIsStable$.subscribe(() => {
            this.pollingSubscription = this.makePollForRequestedBookings().subscribe()
        })
    }

    private makePollForRequestedBookings() {
        const eventTypesCausingRefresh: EventType[] = [
            'booking_accepted',
            'booking_rejected',
        ]
        return this.zone.runOutsideAngular(() => {
            return this.eventService.eventsOfType(eventTypesCausingRefresh)
                .pipe(
                    mergeWith(this.emitPeriodically()),
                    throttleTime(1000),
                    switchMap(() => this.getRequestedBookingsInDateRange()),
                    catchError(() => {
                        return of([])
                    }),
                    tap(bookings => {
                        this.zone.run(() => this.number.next(bookings.length))
                    })
                )
        })
    }

    private emitPeriodically() {
        return new Observable((observer) => {
            const emitFunction = () => {
                observer.next()
                setTimeout(emitFunction, this.refreshRateSeconds * 1000)
            }
            emitFunction()
        })
    }

    private getRequestedBookingsInDateRange(): Observable<Booking[]> {
        const organisation = this.contextService.getOrganisation()
        const business = this.contextService.getBusiness()
        const venue = this.contextService.getVenue()
        if (organisation === null || business === null || venue === null) {
            return of([])
        }
        const maximumInAdvanceDays = organisation.maximumInAdvanceDaysInVenue(venue) ?? 7
        const fromDate = DateTime.now()
            .setZone(venue.timeZone)
            .startOf('day')
            .toJSDate()
        const toDate = DateTime.now()
            .setZone(venue.timeZone)
            .endOf('day')
            .plus({ days: maximumInAdvanceDays })
            .toJSDate()
        return this.bookingService.getBookings(
            business.id,
            venue.id,
            fromDate,
            toDate,
            [BookingStatusType.Requested]
        )
    }
}
