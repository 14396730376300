import { Bookable } from '@app/domain/Bookable'
import { Booking } from '@app/domain/Booking'
import { BookingReason } from '@app/domain/BookingReason'
import { DateComponent } from '@app/domain/DateComponent'
import { Table } from '@app/domain/Table'
import { TableCombination } from '@app/domain/TableCombination'

export class Area {

    constructor(
        public id: string,
        public displayName: string,
        public description: string | null,
        public tableTurnaroundInterval: number,
        public automaticAcceptSchedule: DateComponent[],
        public automaticAcceptPartySize: number | null,
        public displayOrder: number,
        public isActive: boolean,
        public tables: Table[],
        public combinations: TableCombination[],
        public scheduleIds: string[],
        public dateCreated: Date,
        public dateUpdated: Date
    ) { }

    getTableWithId(id: string): Table | null {
        return this.tables.find(t => t.id === id) || null
    }

    getDisplayedTablesBookingIsOn(booking: Booking): Table[] {
        return this.tablesSortedByDisplayOrder
            .filter(table => booking.tableIds.includes(table.id))
    }

    getContiguousTablesForBooking(booking: Booking): Table[] {
        const tables = this.tablesSortedByDisplayOrder
        const tablesUsedForBooking = this.tablesUsedForBooking(booking)
        sortTablesByDisplayOrderInArea(tablesUsedForBooking, this)
        const tableIndexes = tablesUsedForBooking
            .map(table => table.id)
            .map(tableId => tables.findIndex(table => table.id === tableId))
        const firstIndex = tableIndexes[0]
        const contiguousTableIndexes = tableIndexes.filter((index, i) => index === firstIndex + i)
        return contiguousTableIndexes.map(index => tables[index])
    }

    getNonContiguousTablesForBooking(booking: Booking): Table[] {
        const contiguousTables = this.getContiguousTablesForBooking(booking)
        return this.tablesUsedForBooking(booking)
            .filter(table => !contiguousTables.includes(table))
    }

    addTable(table: Table) {
        this.tables = [
            ...this.tables,
            table,
        ]
    }

    updateTable(table: Table) {
        const index = this.tables.findIndex(t => t.id === table.id)
        this.tables = [
            ...this.tables.slice(0, index),
            table,
            ...this.tables.slice(index + 1),
        ]
    }

    removeTable(table: Table) {
        const index = this.tables.findIndex(t => t.id === table.id)
        this.tables = [
            ...this.tables.slice(0, index),
            ...this.tables.slice(index + 1),
        ]
    }

    addCombination(combination: TableCombination) {
        this.combinations = [
            ...this.combinations,
            combination,
        ]
    }

    updateCombination(combination: TableCombination) {
        const index = this.combinations.findIndex(c => c.id === combination.id)
        this.combinations = [
            ...this.combinations.slice(0, index),
            combination,
            ...this.combinations.slice(index + 1),
        ]
    }

    removeCombination(combination: TableCombination) {
        const index = this.combinations.findIndex(c => c.id === combination.id)
        this.combinations = [
            ...this.combinations.slice(0, index),
            ...this.combinations.slice(index + 1),
        ]
    }

    get availableTables(): Table[] {
        return this.tables.sort((table => table.bookingOrder))
    }

    minimumPartySize(): number {
        return Math.min(
            ...this.tables
                .map(table => table.minimumSeats)
        )
    }

    maximumPartySize(): number {
        const tableMaximums = this.tables
            .map(table => table.maximumSeats)
        const combinationMaximums = this.combinations
            .map(combination => combination.maximumSeats)
        return Math.max(
            ...tableMaximums,
            ...combinationMaximums
        )
    }

    tablesUsedForBooking(booking: Booking): Table[] {
        return booking.tableIds.flatMap(tableId => {
            return this.tables.filter(table => table.id === tableId)
        })
    }

    tablesReservableForReasonOrNone(reason: BookingReason | null): Table[] {
        if (reason === null) {
            return this.tables.filter(table => table.reasonIds.length === 0)
        }
        return this.tables.filter(table => table.isReservableForReasonWithId(reason.id))
    }

    tablesReservableForReasonOrNoneOnDate(
        reason: BookingReason | null,
        date: Date
    ): Table[] {
        const tables = this.tablesReservableForReasonOrNone(reason)
        if (reason === null) {
            return tables
        }
        if (reason.appliesToDate(date)) {
            return tables
        }
        return []
    }

    doTablesFitPartySize(tables: Table[], partySize: number): boolean {
        const combination = this.combinations.find(combination => {
            return combination.isFormedFromTables(tables)
        }) || null
        if (combination) {
            return combination.fitsPartySize(partySize)
        }
        const minimumSeats = tables.reduce((total, table) => total + table.minimumSeats, 0)
        const totalSeats = tables.reduce((total, table) => total + table.maximumSeats, 0)
        return partySize >= minimumSeats && partySize <= totalSeats
    }

    getTablesInBookable(bookable: Bookable): Table[] {
        return this.tables.filter(table => bookable.tableIds.includes(table.id))
    }

    get tablesSortedByDisplayOrder() {
        const tables = [...this.tables]
        sortTablesByDisplayOrderInArea(tables, this)
        return tables
    }

    get tablesSortedByBookingOrder() {
        return [...this.tables].sort((a, b) => a.bookingOrder - b.bookingOrder)
    }

    get combinationsSortedByBookingOrder() {
        return [...this.combinations].sort((a, b) => a.bookingOrder - b.bookingOrder)
    }

    get scheduledForManualAcceptance(): boolean {
        return this.automaticAcceptSchedule.length < 7 || this.automaticAcceptPartySize !== null
    }

    totalPartySize(): number {
        return this.tables.reduce((total, table) => total + table.maximumSeats, 0)
    }

    contiguouslyDisplayedTablesInList(tableIds: string[]): Table[][] {
        const allTables = this.tablesSortedByDisplayOrder
        const tables = tableIds
            .map(tableId => this.getTableWithId(tableId))
            .filter(table => table !== null) as Table[]
        sortTablesByDisplayOrderInArea(tables, this)
        const contiguousTables: Table[][] = []
        let currentContiguousTables: Table[] = []
        let previousTable: Table | null = null
        for (const table of tables) {
            if (previousTable === null) {
                currentContiguousTables.push(table)
                previousTable = table
                continue
            }
            const previousTableIndex = allTables.indexOf(previousTable)
            const tableIndex = allTables.indexOf(table)
            if (previousTableIndex === -1) {
                return []
            }
            if (tableIndex === -1) {
                return []
            }
            if (tableIndex === previousTableIndex + 1) {
                currentContiguousTables.push(table)
                previousTable = table
                continue
            }
            contiguousTables.push(currentContiguousTables)
            currentContiguousTables = [table]
            previousTable = table
        }
        if (currentContiguousTables.length > 0) {
            contiguousTables.push(currentContiguousTables)
        }
        return contiguousTables
    }
}

export function sortTablesByDisplayOrderInArea(tables: Table[], area: Area) {
    tables.sort((a, b) => {
        const aIndex = area.tables.indexOf(a) ?? 0
        const bIndex = area.tables.indexOf(b) ?? 0
        const displayOrderDifference = a.displayOrder - b.displayOrder
        const indexDifference = aIndex - bIndex
        if (displayOrderDifference === 0) {
            return indexDifference
        }
        return displayOrderDifference
    })
}
