import { DateTime } from 'luxon'
import { Time } from '@app/domain/Time'
import { environment } from '../../environments/environment'

export class Period {
    constructor(
        public start: Time,
        public end: Time,
        public open: boolean
    ) { }

    containsTime(time: Date): boolean {
        if (!this.open) {
            return false
        }
        const range = this.dateRangeOnDate(time)
        return range[0] <= time && time <= range[1]
    }

    dateRangeOnDate(date: Date): Date[] {
        const start = DateTime.fromJSDate(date)
            .setZone(environment.assumedVenueTimeZone)
            .set({
                hour: this.start.hours,
                minute: this.start.minutes,
                second: 0,
                millisecond: 0,
            })
            .toJSDate()
        const end = DateTime.fromJSDate(date)
            .setZone(environment.assumedVenueTimeZone)
            .set({
                hour: this.end.hours,
                minute: this.end.minutes,
                second: 0,
                millisecond: 0,
            })
            .toJSDate()
        return [start, end]
    }

    isOpenAnyTimeOnDate(date: Date): boolean {
        if (!this.open) {
            return false
        }
        const range = this.dateRangeOnDate(date)
        return range[0] <= range[1]
    }
}
