import { AnswerDTO } from '../../../../services/DTO/AnswerDTO'
import { BookingSlotDTO } from '../../../../services/DTO/BookingSlotDTO'
import { ContactDTO } from '../../../../services/DTO/ContactDTO'

export class BookingRequestDTO {

    constructor(
        public bookingSlot: BookingSlotDTO,
        public contact: ContactDTO,
        public partySize: number,
        public notes: string | null,
        public agreedToMarketing: boolean,
        public agreedToTerms: boolean,
        public paymentMethodId: string | null,
        public reasonId: string | null,
        public eventId: string | null,
        public answers: AnswerDTO[],
        public depositPaymentIntentId: string | null,
        public requiresWheelchairAccess: boolean,
        public requiresDogFriendly: boolean,
        public dateCreated: string
    ) { }
}
