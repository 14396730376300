import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '@services/auth.service'
import { Component, OnInit } from '@angular/core'
import { PATHS } from '@app/routes'

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.sass'],
})
export class AuthCallbackComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    public ngOnInit() {
        const code = this.route.snapshot.queryParamMap.get('code')
        if (code === null) {
            return
        }
        this.handleRedirectCallbackCode(code)
    }

    private handleRedirectCallbackCode(code: string) {
        this.authService.fetchToken(code)
            .then(_tokenData => {
                this.router.navigate([PATHS.diary])
            }, _errorReason => {
                //TODO add a toaster notification or redirect to an error page
            })
    }
}
