import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingReason } from '@app/domain/BookingReason'
import { BookingSlot } from '@app/domain/BookingSlot'
import {
    Component,
    Input,
    OnChanges, OnInit,
} from '@angular/core'
import { DateTime } from 'luxon'
import { Event } from '@app/domain/VenueEvent'
import { Organisation } from '@app/domain/Organisation'
import { Service } from '@app/domain/Service'
import { Venue } from '@app/domain/Venue'

@Component({
    selector: 'app-print-booking-diary',
    templateUrl: './print-booking-diary.component.html',
})
export class PrintBookingDiaryComponent implements OnInit, OnChanges {

    @Input() date!: Date
    @Input() organisation!: Organisation
    @Input() venue!: Venue
    @Input() bookings!: Booking[]
    @Input() service: Service | null = null
    @Input() printType: 'all' | 'large-parties' = 'all'
    @Input() largePartyThreshold: number | null = null

    bookingsOnTables!: {
        booking: Booking,
        reason: BookingReason | null,
        event: Event | null,
        area: Area,
    }[]
    serviceDescription: {
        name: string,
        start: Date,
        end: Date,
    } | null = null

    constructor() { }

    ngOnInit() {
        this.injectStylesToIframe()
    }

    ngOnChanges() {
        this.updateBookingsOnTables()
    }

    private updateBookingsOnTables() {
        const timeRange = this.timeRangeForSelectedService()
        this.bookingsOnTables = this.bookings
            .filter(booking => {
                if (this.printType === 'large-parties') {
                    return booking.size >= (this.largePartyThreshold || 0)
                }
                return true
            })
            .filter(booking => {
                return timeRange === null || booking.startsBetweenDates(timeRange[0], timeRange[1])
            })
            .flatMap(booking => {
                const reason = booking.reasonId ? this.venue.reasonWithId(booking.reasonId) : null
                const event = booking.eventId ? this.venue.eventWithId(booking.eventId) : null
                const area = this.venue.areaBookingIsIn(booking)
                if (area === null) {
                    return []
                }
                return [{
                    booking: booking,
                    reason: reason,
                    event: event,
                    area: area,
                }]
            })
        this.serviceDescription = timeRange !== null ? {
            name: this.service!.displayName,
            start: timeRange[0],
            end: timeRange[1],
        } : null
    }

    private timeRangeForSelectedService(): [Date, Date] | null {
        if (this.service === null) {
            return null
        }
        const servicesMatchingSelectedService = this.organisation
            .coalescedServicesUsedInVenueOnDate(this.venue, this.date)
            .filter(({ service }) => {
                return this.service === null || service.displayName === this.service.displayName
            })
        if (servicesMatchingSelectedService.length === 0) {
            return null
        }
        const timeRange = servicesMatchingSelectedService[0].timeRange
        const endBookingSlot = new BookingSlot(timeRange[1])
        const endOfLastBookingSlot = endBookingSlot.endDateTime(this.venue.bookingInterval)
        return [timeRange[0], endOfLastBookingSlot]
    }

    private injectStylesToIframe() {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = 'styles.css'
        document.head.appendChild(link)
    }

    protected readonly DateTime = DateTime
}
