import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { DateTimePipe } from '@app/features/shared/pipes/date-time.pipe'

@Pipe({
    name: 'dates',
    standalone: true,
})
export class DateArrayPipe implements PipeTransform {

    constructor(
        private datePipe: DateTimePipe
    ) { }

    transform(array: Date[], timeZone: string, dateFormat: Intl.DateTimeFormatOptions, separator: string): string {
        return array
            .map(date => this.datePipe.transform(date, timeZone, dateFormat))
            .join(separator)
    }
}
