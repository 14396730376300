import { Booking } from './Booking'
import { BookingAuditEntry } from './BookingAuditEntry'
import { BookingStatus, BookingStatusType, CancellationReason } from './BookingStatus'
import { CurrencyUnitPipe } from '@app/features/shared/pipes/currency-unit.pipe'
import { Helper } from '@app/helpers/utilities/helper'
import { Table } from './Table'
import { TableNamePipe } from '../features/shared/pipes/table-name.pipe'
import { Time } from '@app/domain/Time'
import { Venue } from './Venue'

export class BookingHistoryFactory {

    constructor(
        private booking: Booking,
        private auditLog: BookingAuditEntry[],
        private currencyUnitPipe: CurrencyUnitPipe,
        private tableNamePipe: TableNamePipe,
        private venue: Venue
    ) { }

    make() {
        return [
            ...this.adaptStatusHistoryToLabels(),
            ...this.adaptReminderToLabels(),
            ...this.adaptCancellationChargeToLabels(),
            ...this.adaptAuditLogToLabels(),
        ]
            .sort((a, b) => {
                return b.date.getTime() - a.date.getTime()
            })
    }

    private adaptStatusHistoryToLabels() {
        return this.booking.statusEvents
            .map((statusEvent) => {
                return {
                    ...this.adaptBookingStatusToLabels(statusEvent),
                    date: statusEvent.dateTime,
                }
            })
    }

    private adaptReminderToLabels() {
        const reminderSentHistory = this.booking.reminders
            .map((reminder) => ({
                title: 'Booking Reminder Email Sent',
                date: reminder.sentDateTime,
            }))
        const reminderConfirmationHistory = this.booking.reminders
            .filter((reminder) => {
                return reminder.confirmedDateTime !== null
            })
            .map((reminder) => ({
                title: 'Customer Confirmed Booking',
                date: reminder.confirmedDateTime!,
            }))
        return [
            ...reminderSentHistory,
            ...reminderConfirmationHistory,
        ]
    }

    private adaptCancellationChargeToLabels() {
        if (!this.booking.cancellation) {
            return []
        }
        return this.booking.cancellation.charges
            .map((charge) => {
                const amount = this.currencyUnitPipe.transform(
                    null,
                    charge.amount,
                    charge.currencyCode
                )
                const subtitle = `Card charged ${amount}`
                return {
                    title: 'Cancellation Charge',
                    subtitle: subtitle,
                    date: charge.dateCreated,
                }
            })
    }

    private adaptAuditLogToLabels() {
        return this.auditLog
            .flatMap((auditEntry, index) => {
                const changeLabels = this.adaptAuditEntryToLabels(auditEntry, index)
                return changeLabels.map((changeLabel) => {
                    let performedBy: string | undefined = auditEntry.userId !== null ? 'merchant' : 'customer'
                    const labelPerformedBySystem = changeLabel.performedBySystem
                    if (labelPerformedBySystem) {
                        performedBy = undefined
                    }
                    return {
                        title: changeLabel.title,
                        subtitle: changeLabel.subtitle,
                        date: auditEntry.dateTime,
                        performedBy: performedBy,
                    }
                })
            })
    }

    private adaptBookingStatusToLabels(status: BookingStatus) {
        switch (status.type) {
        case BookingStatusType.Requested:
            return {
                title: 'Customer Requested Booking',
            }
        case BookingStatusType.PendingPayment:
            return this.pendingPaymentLabel(status)
        case BookingStatusType.Booked:
            return {
                title: 'Booking Confirmed',
            }
        case BookingStatusType.Cancelled:
            return this.cancelledLabel(status)
        case BookingStatusType.NoShow:
            return {
                title: 'Customer No Show',
            }
        case BookingStatusType.Waiting:
            return {
                title: 'Waiting at Bar',
            }
        case BookingStatusType.PartiallySeated:
            return {
                title: 'Partially Seated',
            }
        case BookingStatusType.Seated:
            return {
                title: 'Customer Seated',
            }
        case BookingStatusType.Finished:
            return {
                title: 'Booking Finished',
            }
        case BookingStatusType.Rejected:
            return {
                title: 'Booking Rejected',
            }
        }
    }

    private pendingPaymentLabel(status: BookingStatus) {
        if (status.cancellationCharge()) {
            return {
                title: 'Pending Card Details',
            }
        }
        if (status.depositCharge()) {
            return {
                title: 'Pending Deposit Payment',
            }
        }
        return {
            title: 'Pending Payment',
        }
    }

    private cancelledLabel(status: BookingStatus) {
        const information = status.cancellationInformation()
        if (!information) {
            return {
                title: 'Booking Cancelled',
            }
        }
        if (information.reason === CancellationReason.ChargeExpired) {
            return {
                title: 'Booking Cancelled',
                subtitle: 'Pending Charge Expired',
            }
        }
        if (information.cancelledByCustomer) {
            return {
                title: 'Booking Cancelled',
                subtitle: 'Cancelled by Customer',
            }
        }
        const emailAddress = information.cancelledByUserEmailAddress
        if (emailAddress) {
            return {
                title: 'Booking Cancelled by Merchant',
                subtitle: `Cancelled by ${emailAddress}`,
            }
        }
        return {
            title: 'Booking Cancelled',
        }
    }

    private adaptAuditEntryToLabels(auditEntry: BookingAuditEntry, index: number) {
        if (index === 0) {
            return []
        }
        const changes: { title: string, subtitle?: string, performedBySystem?: boolean }[] = []
        this.makeAuditEntryChangeLabels(auditEntry, index)
            .forEach((change) => changes.push(change))
        return changes
    }

    private makeAuditEntryChangeLabels(auditEntry: BookingAuditEntry, index: number) {
        const dateOfChange = auditEntry.dateTime
        const bookingCreationDate = this.booking.dateCreated
        if (dateOfChange.getTime() === bookingCreationDate.getTime()) {
            return []
        }
        const changes: { title: string, subtitle?: string, performedBySystem?: boolean }[] = []
        if (auditEntry.changes['start']) {
            const date = new Date(auditEntry.changes['start'])
            const previousDate = this.getBookingStartBeforeAuditEntryAtIndex(index)
            const dateHasChanged = previousDate
                && date.toDateString() !== previousDate.toDateString()
            if (dateHasChanged) {
                const subtitle = `From ${Helper.makeLocalISOFormattedDateString(previousDate)} `
                + `to ${Helper.makeLocalISOFormattedDateString(date)}`
                changes.push({
                    title: 'Date Changed',
                    subtitle: subtitle,
                })
            }
            const time = new Time(null, date.getHours(), date.getMinutes())
            const previousTime = previousDate ? new Time(null, previousDate.getHours(), previousDate.getMinutes()) : null
            const timeHasChanged = previousTime && !time.equals(previousTime)
            if (timeHasChanged) {
                const subtitle = `From ${previousTime.toString()} to ${time.toString()}`
                changes.push({
                    title: 'Time Changed',
                    subtitle: subtitle,
                })
            }
        }
        if (auditEntry.changes['size']) {
            const size = auditEntry.changes['size']
            let subtitle = `${size} covers`
            const previousSize = this.getSizeBeforeAuditEntryAtIndex(index)
            const sizeHasChanged = previousSize && size !== previousSize
            if (sizeHasChanged) {
                subtitle = `From ${previousSize} to ${size} covers`
            }
            changes.push({
                title: 'Party Size Changed',
                subtitle: subtitle,
            })
        }
        if (auditEntry.changes['duration']) {
            const duration = auditEntry.changes['duration']
            let subtitle = `${duration} minutes`
            const previousDuration = this.getDurationBeforeAuditEntryAtIndex(index)
            const durationHasChanged = previousDuration && duration !== previousDuration
            if (durationHasChanged) {
                subtitle = `From ${previousDuration} to ${duration} minutes`
            }
            changes.push({
                title: 'Duration Changed',
                subtitle: subtitle,
            })
        }
        if (auditEntry.changes['tableIds']) {
            const tableIds = auditEntry.changes['tableIds'] as string[]
            const tables = tableIds
                .map((tableId) => this.venue.areaWithTableId(tableId)?.getTableWithId(tableId))
                .filter((table) => table !== undefined)
                .filter((table) => table !== null) as Table[]
            let subtitle = this.tableNamePipe.transform(undefined, undefined, 'short', tables)
            const previousTableIds = this.getTableIdsBeforeAuditEntryAtIndex(index)
            const tableHasChanged = previousTableIds
                && tableIds.join(',') !== previousTableIds.join(',')
            if (tableHasChanged) {
                const previousTables = previousTableIds
                    .map((tableId) => this.venue.areaWithTableId(tableId)?.getTableWithId(tableId))
                    .filter((table) => table !== undefined)
                    .filter((table) => table !== null) as Table[]
                const previousSubtitle = this.tableNamePipe.transform(
                    undefined,
                    undefined,
                    'short',
                    previousTables
                )
                subtitle = `From ${previousSubtitle} to ${subtitle}`
            }
            changes.push({
                title: 'Table Changed',
                subtitle: subtitle,
            })
        }
        if (auditEntry.changes['reasonId']) {
            const reasonId = auditEntry.changes['reasonId'] as string
            const reason = this.venue.reasonWithId(reasonId)
            let subtitle = reason?.displayName
            const previousReasonId = this.getReasonIdBeforeAuditEntryAtIndex(index)
            const reasonHasChanged = previousReasonId && reasonId !== previousReasonId
            if (reasonHasChanged && previousReasonId) {
                const previousReason = this.venue.reasonWithId(previousReasonId)
                const previousSubtitle = previousReason?.displayName
                subtitle = `From ${previousSubtitle} to ${subtitle}`
            }
            changes.push({
                title: 'Reason Changed',
                subtitle: subtitle,
            })
        }
        if (auditEntry.changes['notes']) {
            const notes = auditEntry.changes['notes'] as string
            changes.push({
                title: 'Customer Message',
                subtitle: notes,
            })
        }
        if (auditEntry.changes['customerId']) {
            const customerId = auditEntry.changes['customerId'] as string
            const detached = customerId === null
            const attached = customerId !== null
            if (detached) {
                changes.push({
                    title: 'Detached from Customer Profile',
                })
            }
            if (attached) {
                changes.push({
                    title: 'Attached to Customer Profile',
                })
            }
        }
        if (auditEntry.changes['pendingPayment']) {
            const cancellationAmount = auditEntry.changes['cancellationChargeAmount'] as number
            if (cancellationAmount !== undefined) {
                const amount = this.currencyUnitPipe.transform(
                    null,
                    cancellationAmount,
                    'GBP'
                )
                changes.push({
                    title: 'Cancellation Charge Added',
                    subtitle: `Amount: ${amount}`,
                })
            }
            const depositAmount = auditEntry.changes['depositChargeAmount'] as number
            if (depositAmount !== undefined) {
                const amount = this.currencyUnitPipe.transform(
                    null,
                    depositAmount,
                    'GBP'
                )
                changes.push({
                    title: 'Deposit Charge Added',
                    subtitle: `Amount: ${amount}`,
                })
            }
        }
        const updatedChargeAmount = auditEntry.changes['updatedChargeAmount'] as number
        if (updatedChargeAmount !== undefined) {
            const amount = this.currencyUnitPipe.transform(
                null,
                updatedChargeAmount,
                'GBP'
            )
            changes.push({
                title: 'Charge Amount Updated',
                subtitle: `Amount: ${amount}`,
            })
        }
        const bookingMessageEntry = this.makeBookingMessageEntry(auditEntry)
        if (bookingMessageEntry) {
            changes.push(bookingMessageEntry)
        }
        return changes
    }

    private makeBookingMessageEntry(auditEntry: BookingAuditEntry) {
        if (!auditEntry.changes['messageType']) {
            return
        }
        const messageType = auditEntry.changes['messageType'] as string
        const isEmail = auditEntry.changes['isEmail'] as boolean
        const isSMS = auditEntry.changes['isSMS'] as boolean
        const subtitle = 'Sent via ' + (isEmail ? 'Email' : '') + (isSMS ? 'SMS' : '')
        let title = ''
        switch (messageType) {
        case 'CONFIRMATION':
            title = 'Booking Confirmation Sent'
            break
        case 'MODIFICATION':
            title = 'Booking Modification Sent'
            break
        case 'CANCELLATION':
            title = 'Booking Cancellation Sent'
            break
        case 'REJECTION':
            title = 'Booking Rejection Sent'
            break
        case 'NO_SHOW':
            title = 'No Show Sent'
            break
        case 'PENDING_PAYMENT':
            title = 'Pending Payment Sent'
            break
        case 'PENDING_PAYMENT_REMINDER':
            title = 'Pending Payment Reminder Sent'
            break
        case 'REMINDER':
            title = 'Booking Reminder Sent'
            break
        case 'FEEDBACK':
            title = 'Booking Feedback Request Sent'
            break
        case 'REQUEST':
            title = 'Booking Request Acknowledgement Sent'
            break
        }
        return {
            title: title,
            subtitle: subtitle,
            performedBySystem: true,
        }
    }

    private getBookingStartBeforeAuditEntryAtIndex(index: number) {
        if (index === 0) {
            return null
        }
        for (let i = index - 1; i >= 0; i--) {
            const entry = this.auditLog[i]
            if (entry.changes['start']) {
                return new Date(entry.changes['start'])
            }
        }
        return null
    }

    private getDurationBeforeAuditEntryAtIndex(index: number) {
        if (index === 0) {
            return null
        }
        for (let i = index - 1; i >= 0; i--) {
            const entry = this.auditLog[i]
            if (entry.changes['duration']) {
                return entry.changes['duration'] as number
            }
        }
        return null
    }

    private getTableIdsBeforeAuditEntryAtIndex(index: number) {
        if (index === 0) {
            return null
        }
        for (let i = index - 1; i >= 0; i--) {
            const entry = this.auditLog[i]
            if (entry.changes['tableIds']) {
                return entry.changes['tableIds'] as string[]
            }
        }
        return null
    }

    private getReasonIdBeforeAuditEntryAtIndex(index: number) {
        if (index === 0) {
            return null
        }
        for (let i = index - 1; i >= 0; i--) {
            const entry = this.auditLog[i]
            if (entry.changes['reasonId']) {
                return entry.changes['reasonId'] as string
            }
        }
        return null
    }

    private getSizeBeforeAuditEntryAtIndex(index: number) {
        if (index === 0) {
            return null
        }
        for (let i = index - 1; i >= 0; i--) {
            const entry = this.auditLog[i]
            if (entry.changes['size']) {
                return entry.changes['size'] as number
            }
        }
        return null
    }
}
