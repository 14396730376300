import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingChangedMetadata } from '@app/features/shared/components/booking/booking.component'
import {
    BookingDetailsViewModel,
    BookingDetailsViewModelDelegate,
} from '@app/features/shared/components/booking-details/booking-details-view-model'
import { BookingReason } from '../../../../domain/BookingReason'
import { BookingReasonChoice } from '../../../bookings/components/booking-reason-choice'
import { BookingService } from '@services/booking.service'
import {
    BookingStartEditComponent,
} from '@app/features/bookings/components/booking-start-edit/booking-start-edit.component'
import { Business } from '@app/domain/Business'
import { ClipboardService } from 'ngx-clipboard'
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { ContextService } from '@services/context.service'
import { Customer } from '@app/domain/Customer'
import { DateTime } from 'luxon'
import { DateTimePipe } from '../../pipes/date-time.pipe'
import { DiaryPreferencesService } from '@app/features/bookings/services/diary-preferences.service'
import {
    ElementChoiceSelectComponent,
} from '@app/features/shared/components/element-choice-select/element-choice-select.component'
import { FormControl, Validators } from '@angular/forms'
import { Helper } from '@app/helpers/utilities/helper'
import { InputComponent, InputType } from '@app/features/shared/components/input/input.component'
import { ModalComponent } from '@app/features/shared/components/modal/modal.component'
import { ModelCloningService } from '@services/model-cloning.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subject, delay, finalize, map, mergeMap, of, takeUntil, tap } from 'rxjs'
import { Table } from '@app/domain/Table'
import { TableNamePipe } from '@app/features/shared/pipes/table-name.pipe'
import {
    TableSelectionComponent,
} from '@app/features/bookings/components/table-selection/table-selection.component'
import { TextareaComponent } from '@app/features/shared/components/textarea/textarea.component'
import { TimeElementChoice } from '@app/features/bookings/components/time-element-choice'
import { ToastBuilder } from '@app/domain/ToastBuilder'
import { ToastService } from '@services/toast.service'
import { ToastType } from '@app/domain/Toast'
import { Venue } from '@app/domain/Venue'
import { VenueSchedule } from '@app/domain/VenueSchedule'

@Component({
    selector: 'app-booking-details',
    templateUrl: './booking-details.component.html',
})
export class BookingDetailsComponent implements
    OnInit,
    OnDestroy,
    BookingDetailsViewModelDelegate {

    @Input() business!: Business
    @Input() venue!: Venue
    @Input() booking!: Booking
    @Input() otherBookings!: Booking[]
    @Input() schedule!: VenueSchedule | null
    @Output() bookingChanged = new EventEmitter<[Booking, Partial<BookingChangedMetadata>]>()
    @Output() customerSelected = new EventEmitter<Customer>()
    @Output() paymentsSelected = new EventEmitter<void>()
    area!: Area
    viewModel!: BookingDetailsViewModel
    bookingTimeViewStartingSelection?: TimeElementChoice
    bookingReasonStartingSelection?: BookingReasonChoice
    pendingPaymentDeliveryMethod: FormControl = new FormControl(null, [Validators.required])

    @ViewChild('changeEndTime', { static: true })
        bookingTimeView!: ElementChoiceSelectComponent<Date>
    @ViewChild('changeBookingReason', { static: true })
        bookingReasonView!: ElementChoiceSelectComponent<BookingReason | null>
    @ViewChild('customerMessage', { static: true })
        customerMessageView!: TextareaComponent
    @ViewChild('merchantNotes', { static: true })
        merchantNotesView!: TextareaComponent
    @ViewChild('partySize', { static: true })
        partySizeView!: InputComponent
    private onDestroy = new Subject<void>()

    constructor(
        private bookingService: BookingService,
        private clipboardService: ClipboardService,
        private contextService: ContextService,
        private datePipe: DateTimePipe,
        private diaryPreferencesService: DiaryPreferencesService,
        private modalService: NgbModal,
        private modelCloningService: ModelCloningService,
        private tableNamePipe: TableNamePipe,
        private toastService: ToastService
    ) { }

    ngOnInit() {
        this.area = this.venue.areaBookingIsIn(this.booking)!
        this.viewModel = new BookingDetailsViewModel(
            this.venue,
            this.booking,
            this.diaryPreferencesService.getHidePrivateNotes(),
            this
        )
        this.bookingTimeViewStartingSelection =
            new TimeElementChoice(this.booking.end, this.venue.timeZone, this.datePipe)
        if (this.booking.reasonId) {
            const reason = this.venue.reasonWithId(this.booking.reasonId)
            this.bookingReasonStartingSelection = new BookingReasonChoice(reason)
        } else {
            this.bookingReasonStartingSelection = new BookingReasonChoice(null)
        }
        this.bookingChanged
            .pipe(
                takeUntil(this.onDestroy)
            )
            .subscribe(([booking, _]) => {
                this.booking = booking
                this.area = this.venue.areaBookingIsIn(this.booking)!
                this.viewModel = new BookingDetailsViewModel(
                    this.venue,
                    this.booking,
                    this.diaryPreferencesService.getHidePrivateNotes(),
                    this
                )
                this.bookingTimeViewStartingSelection =
                    new TimeElementChoice(this.booking.end, this.venue.timeZone, this.datePipe)
            })
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    showChangeBookingStartTimeOptions() {
        if (this.schedule === null) {
            return
        }
        const modal = this.modalService.open(BookingStartEditComponent, {
            size: 'lg',
            scrollable: true,
        })
        const component = modal.componentInstance as BookingStartEditComponent
        component.venue = this.venue
        component.booking = this.booking
        modal.closed
            .pipe(
                takeUntil(this.onDestroy)
            )
            .subscribe((booking: Booking) => {
                this.booking = booking
                this.bookingChanged.emit([booking, {}])
                this.showSuccess(
                    `Booking start time changed to ${this.datePipe.transform(booking.start, this.venue.timeZone, DateTime.DATETIME_SHORT)}`,
                    'Booking Updated'
                )
            })
    }

    showChangeBookingReasonOptions() {
        const reasons = [new BookingReasonChoice(null)]
        this.venue.reasons.forEach(reason => {
            reasons.push(new BookingReasonChoice(reason))
        })
        this.bookingReasonView.elementChoices = of(reasons)
    }

    showChangeBookingEndTimeOptions() {
        const availableEndDates = this.findAvailableBookingEndTimes()
        if (availableEndDates.length === 0) {
            this.bookingTimeView.cancelElementUpdate()
            this.showError(
                'Couldn\'t load available booking end times. Please try again.',
                'Booking Update Error'
            )
        } else {
            this.bookingTimeView.elementChoices =
                of(availableEndDates.map(date => new TimeElementChoice(date, this.venue.timeZone, this.datePipe)))
        }
    }

    showChangeBookingTableOptions() {
        const modal = this.modalService.open(TableSelectionComponent, {
            size: 'xl',
            scrollable: true,
            modalDialogClass: 'modal-md-block',
        })
        const currentTables = this.area.tablesUsedForBooking(this.booking)
        const component = modal.componentInstance as TableSelectionComponent
        component.currentlySelectedTables = currentTables
        component.venue = this.venue
        component.bookings = this.otherBookings
        component.partySize = this.booking.size
        component.startDate = this.booking.start
        component.endDate = this.booking.end
        const savingTableSelection = new Subject<boolean>()
        component.isSavingSelection = savingTableSelection.asObservable()
        component.tablesSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(_ => savingTableSelection.next(true)),
                delay(1000),
                finalize(() => savingTableSelection.next(false)),
                mergeMap((selectedTables: Table[]) => {
                    return this.requestChangeBookingTables(selectedTables)
                })
            )
            .subscribe({
                next: booking => {
                    modal.close()
                    this.booking = booking
                    this.bookingChanged.emit([booking, {}])
                    const tableName = this.tableNamePipe.transform(
                        booking, this.area
                    )
                    this.showSuccess(
                        `Booking table changed to ${tableName}`
                    )
                },
                error: _ => {
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update the table for this booking.' +
                        'Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    updateBookingTime(time: Date) {
        this.viewModel.isEditingBooking = true
        const updatedBooking = { ...this.booking,
            duration: Helper.minutesBetweenDates(this.booking.start, time) } as Booking
        this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.bookingTimeView.finishElementUpdate()
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        `Booking duration changed to ${booking.duration} minutes`,
                        'Booking Updated'
                    )
                },
                error: _ => {
                    this.bookingTimeView.cancelElementUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this booking\'s time. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    updateBookingReason(reason: BookingReason | null) {
        this.viewModel.isEditingBooking = true
        const updatedBooking = this.modelCloningService.cloneBooking(this.booking)
        updatedBooking.reasonId = reason?.id ?? null
        this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.bookingReasonView.finishElementUpdate()
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        'Booking reason updated',
                        'Booking Updated'
                    )
                },
                error: _ => {
                    this.bookingReasonView.cancelElementUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this booking\'s reason. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    updateCustomerMessage(message: string | null) {
        this.viewModel.isEditingBooking = true
        const updatedBooking = this.modelCloningService.cloneBooking(this.booking)
        updatedBooking.notes = message
        this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.customerMessageView.finishTextUpdate()
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        'Customer\'s Booking Notes updated',
                        'Booking Updated'
                    )
                },
                error: _ => {
                    this.customerMessageView.cancelTextUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update the customer\'s booking notes. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    updateMerchantNotes(merchantNotes: string | null): void {
        this.viewModel.isEditingBooking = true
        const updatedBooking = { ...this.booking,
            merchantNotes: merchantNotes } as Booking
        this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.merchantNotesView.finishTextUpdate()
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        'Your Booking Notes updated',
                        'Booking Updated'
                    )
                },
                error: _ => {
                    this.merchantNotesView.cancelTextUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update the booking\'s notes. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    updatePartySize(partySize: string | null): void {
        const partySizeNumber =  parseInt(partySize || '')

        if (isNaN(partySizeNumber)) {
            this.partySizeView.cancelInputUpdate()
            this.showError(
                'Couldn\'t update this booking\'s size. Please try again.',
                'Booking Update Error'
            )
            return
        }

        this.viewModel.isEditingBooking = true
        const updatedBooking = { ...this.booking,
            size: partySizeNumber } as Booking
        this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.partySizeView.finishInputUpdate()
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        'Booking Party Size updated',
                        'Booking Updated'
                    )
                },
                error: _ => {
                    this.partySizeView.cancelInputUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this booking\'s size. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    copyPendingPaymentLinkToClipboard() {
        const bookingLink = this.bookingService.getBookingDetailsUrl(this.booking)
        this.clipboardService.copy(bookingLink)
        this.showSuccess(
            'Pending payment link copied to clipboard',
            'Copied'
        )
    }

    resendPendingPaymentRequested(modalContent: TemplateRef<any>) {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return
        }
        const business = this.contextService.getBusiness()
        if (business === null) {
            return
        }
        const venue = this.contextService.getVenue()
        if (venue === null) {
            return
        }
        const modal = this.modalService.open(ModalComponent)
        const component = modal.componentInstance as ModalComponent
        component.title = 'Resend Pending Payment Link'
        component.contentTemplate = modalContent
        component.actionType = 'confirm'
        component.actionButtonTitle = 'Resend Link'
        component.actionValidation = this.pendingPaymentDeliveryMethod.valueChanges
            .pipe(
                map(value => value !== null)
            )
        if (this.booking.emailAddress !== null) {
            this.pendingPaymentDeliveryMethod.setValue('email')
        } else if (this.booking.phoneNumber !== null) {
            this.pendingPaymentDeliveryMethod.setValue('sms')
        }
        component.actionSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    const sendViaEmail = this.pendingPaymentDeliveryMethod.value === 'email'
                    const sendViaSms = this.pendingPaymentDeliveryMethod.value === 'sms'
                    return this.bookingService.bookingPendingPaymentResendRequested(
                        organisation,
                        business,
                        venue,
                        this.booking,
                        sendViaEmail,
                        sendViaSms
                    )
                })
            )
            .subscribe({
                next: _ => {
                    modal.close()
                    this.showSuccess(
                        'Pending payment link resent',
                        'Success'
                    )
                },
                error: _ => {
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t resend the pending payment link. Please try again.',
                        'Error'
                    )
                },
            })
    }

    toggleBookingLockedToTables() {
        const updatedBooking = this.modelCloningService.cloneBooking(this.booking)
        updatedBooking.lockedToTables = !this.booking.lockedToTables
        this.viewModel.isEditingBooking = true
        this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        booking.lockedToTables ?
                            'Locked to tables' :
                            'Unlocked from tables',
                        'Booking Updated'
                    )
                },
                error: _ => {
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this booking. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    goToCustomer() {
        const customer = this.booking.customer
        if (customer === null) {
            return
        }
        this.customerSelected.emit(customer)
    }

    private findAvailableBookingEndTimes(): Date[] {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return []
        }
        const area = this.venue.areaBookingIsIn(this.booking)
        if (!area) {
            return []
        }
        return organisation.unoccupiedEndTimesForBookingInVenue(
            this.venue,
            this.booking,
            this.otherBookings
        )
    }

    private requestChangeBookingTables(selectedTables: Table[]): Observable<Booking> {
        const updatedBooking = {
            ...this.booking,
            tableIds: selectedTables.map(table => table.id),
        } as Booking
        return this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy)
            )
    }

    private showError(message: string, title?: string) {
        this.showToast(ToastType.Danger, message, title)
    }

    private showSuccess(message: string, title?: string) {
        this.showToast(ToastType.Success, message, title)
    }

    private showToast(toastType: ToastType, message: string, title?: string) {
        let  toastBuilder = new ToastBuilder(message)
            .withType(toastType)

        if (title){
            toastBuilder = toastBuilder.withHeader(title)
        }

        this.toastService.show(toastBuilder.build())
    }

    protected readonly InputType = InputType
    protected readonly DateTime = DateTime
}
