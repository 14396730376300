import { DateTime } from 'luxon'
import { DateTimePipe } from './date-time.pipe'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {

    constructor(
        private datePipe: DateTimePipe
    ) { }

    transform(
        date: Date | null,
        timeZone: string,
        format?: Intl.DateTimeFormatOptions,
        formatString?: string
    ): string  | null {
        if (!date) {
            return null
        }
        const today = DateTime.now().setZone(timeZone).toJSDate()
        const dateIsToday = date.toDateString() === today.toDateString()
        const tomorrow = DateTime.now().plus({ days: 1 }).setZone(timeZone).toJSDate()
        const dateIsTomorrow = date.toDateString() === tomorrow.toDateString()
        const formatsIndicatingTime = [
            DateTime.TIME_24_SIMPLE,
            DateTime.TIME_24_WITH_SECONDS,
            DateTime.TIME_SIMPLE,
            DateTime.DATETIME_FULL,
            DateTime.DATETIME_FULL_WITH_SECONDS,
            DateTime.DATETIME_MED,
            DateTime.DATETIME_MED_WITH_SECONDS,
            DateTime.DATETIME_SHORT,
            DateTime.DATETIME_SHORT_WITH_SECONDS,
        ]
        const shouldIncludeTime = format && formatsIndicatingTime.includes(format)
        const timeFormat = shouldIncludeTime ? DateTime.TIME_24_SIMPLE : undefined
        if (dateIsToday) {
            return 'Today' + (timeFormat ? ', ' + this.datePipe.transform(date, timeZone, timeFormat, formatString) : '')
        }
        if (dateIsTomorrow) {
            return 'Tomorrow' + (timeFormat ? ', ' + this.datePipe.transform(date, timeZone, timeFormat, formatString) : '')
        }
        return this.datePipe.transform(date, timeZone, format, formatString)
    }
}
