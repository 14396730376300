import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatestWith,
    filter,
    map,
    scan,
    takeUntil,
} from 'rxjs'
import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { DateRange } from '@app/domain/date-range'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Helper } from '@app/helpers/utilities/helper'
import {
    NgbDate,
    NgbDateAdapter,
    NgbDateNativeAdapter,
    NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap'
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning'
import { startWith } from 'rxjs/operators'
import { DateTime } from 'luxon'
import { DateTimeDateAdapter } from '@app/helpers/utilities/NgbDateTimeAdapter'

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {

    @Input() startDate: Date | null = null
    @Input() endDate: Date | null = null
    @Input() timeZone!: string
    @Input() style: 'icon' | 'text' = 'icon'
    @Input() placement?: PlacementArray
    selectedRange$!: Observable<DateRange>
    form!: FormGroup
    hoveredDate: NgbDate | null = null
    private selectedRange!: BehaviorSubject<DateRange>
    private ngbDateAdapter!: NgbDateAdapter<DateTime>
    private onDestroy$ = new Subject<void>()

    constructor(
        public formatter: NgbDateParserFormatter,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            startDate: [null, null],
            endDate: [null, null],
        })
        this.selectedRange = new BehaviorSubject<DateRange>({
            fromDate: Helper.startOfWeek(),
            toDate: Helper.endOfWeek(),
        })
        this.selectedRange$ = this.selectedRange.asObservable()
    }

    ngOnInit() {
        this.ngbDateAdapter = new DateTimeDateAdapter(this.timeZone)
        this.bindFormToOutput()
    }

    ngOnChanges() {
        if (this.startDate !== null) {
            this.form.get('startDate')?.setValue(this.makeNgbDate(this.startDate))
        }
        if (this.endDate !== null) {
            this.form.get('endDate')?.setValue(this.makeNgbDate(this.endDate))
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    get fromDate() {
        return this.form.get('startDate')?.value
    }

    get toDate() {
        return this.form.get('endDate')?.value
    }

    get hasValidSelection(): boolean {
        return this.fromDate !== null && this.toDate !== null
    }

    setDateRange(dateRange: DateRange) {
        this.form.get('startDate')?.setValue(this.makeNgbDate(dateRange.fromDate))
        this.form.get('endDate')?.setValue(this.makeNgbDate(dateRange.toDate!))
    }

    isHovered(date: NgbDate) {
        return this.hoveredDate === date
    }

    isHoveredDateInRange(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate
            && date.after(this.fromDate) && date.before(this.hoveredDate)
    }

    isInRange(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate)
    }

    isRangeStart(date: NgbDate) {
        return date.equals(this.fromDate) && !date.equals(this.toDate)
    }

    isRangeEnd(date: NgbDate) {
        return date.equals(this.toDate) && !date.equals(this.fromDate)
    }

    onDateSelection(date: NgbDate, datePicker: any) {
        if (!this.fromDate && !this.toDate) {
            this.form.get('startDate')?.setValue(date)
        } else if (this.fromDate && !this.toDate && !date.before(this.fromDate)) {
            this.form.get('endDate')?.setValue(date)
            datePicker.close()
        } else {
            this.form.get('endDate')?.setValue(null)
            this.form.get('startDate')?.setValue(date)
        }
    }

    private makeNgbDate(date: Date) {
        const dateTime = DateTime.fromJSDate(date).setZone(this.timeZone)
        return new NgbDate(
            dateTime.year,
            dateTime.month,
            dateTime.day
        )
    }


    private bindFormToOutput() {
        const startDate = this.form.get('startDate')!
        const endDate = this.form.get('endDate')!
        startDate.valueChanges
            .pipe(
                startWith(startDate.value),
                combineLatestWith(endDate.valueChanges.pipe(startWith(endDate.value))),
                takeUntil(this.onDestroy$),
                scan(([_, prevEndDate], [currStartDate, currEndDate]) => {
                    return [
                        currStartDate,
                        currEndDate !== prevEndDate ? currEndDate : null,
                    ]
                }, [startDate.value, endDate.value]),
                filter(([startDate, endDate]) => startDate !== null && endDate !== null),
                map(([startDate, endDate]) => {
                    return {
                        fromDate: this.makeDate(startDate!),
                        toDate: this.makeDate(endDate!),
                    } as DateRange
                })
            )
            .subscribe(range => {
                this.selectedRange.next(range)
            })
    }

    private makeDate(ngbDate: NgbDate) {
        const date = this.ngbDateAdapter.toModel(ngbDate)
        if (date === null) {
            return null
        }
        return date.startOf('day').toJSDate()
    }
}
