import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { DateTime } from 'luxon'
import { DateTimeDateAdapter } from '../../../../helpers/utilities/NgbDateTimeAdapter'
import { FormBuilder, FormGroup } from '@angular/forms'
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { Options } from '@popperjs/core'
import { Subject, map, takeUntil } from 'rxjs'
import { environment } from '../../../../../environments/environment'
import { filterNull } from '@app/features/shared/rjxs/filter.null'

@Component({
    selector: 'app-day-picker',
    templateUrl: './day-picker.component.html',
    styleUrls: ['./day-picker.component.sass'],
})
export class DayPickerComponent implements OnInit, OnDestroy {

    @Input() style: 'toolbar' | 'title' | 'dropdown' = 'toolbar'
    @Output() daySelected = new EventEmitter<DateTime>()
    form: FormGroup
    private ngbDateAdapter = new DateTimeDateAdapter(environment.assumedVenueTimeZone)
    private onDestroy = new Subject<void>()

    constructor(
        private calendar: NgbCalendar,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            date: [null, null],
        })
    }

    ngOnInit() {
        this.form.get('date')!.valueChanges
            .pipe(
                takeUntil(this.onDestroy),
                map((date: NgbDate) => {
                    if (date === null) {
                        return null
                    }
                    return this.ngbDateAdapter.toModel(date)
                }),
                filterNull()
            )
            .subscribe(date => {
                this.daySelected.emit(date)
            })
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    decrementDate() {
        const date = this.form.get('date')?.value
        if (date === null) {
            return
        }
        const previousDate = this.calendar.getNext(date, 'd', -1)
        this.form.get('date')?.setValue(previousDate)
    }

    incrementDate() {
        const date = this.form.get('date')?.value
        if (date === null) {
            return
        }
        const nextDate = this.calendar.getNext(date, 'd', 1)
        this.form.get('date')?.setValue(nextDate)
    }

    suggestionSelected(offset: number) {
        const today = DateTime.now().setZone(environment.assumedVenueTimeZone)
        const date = this.makeNgbDate(today)
        if (date === null) {
            return
        }
        let nextDate: NgbDate
        if (offset >= 0) {
            nextDate = this.calendar.getNext(date, 'd', offset)
        } else {
            nextDate = this.calendar.getPrev(date, 'd', -offset)
        }
        this.form.get('date')?.setValue(nextDate)
    }

    setDate(date: DateTime) {
        const ngbDate = this.makeNgbDate(date)
        if (ngbDate === null) {
            return
        }
        this.form.get('date')?.setValue(ngbDate)
    }

    offsetCalendarPopoverToBelowNavBar(options: Partial<Options>): Partial<Options> {
        const offsetModifier = options.modifiers?.find(m => {
            return m.name === 'offset' && m.options
        }) as { name: string, options: { offset: number[] } }
        offsetModifier.options.offset = [0, 28]
        return options
    }

    private makeNgbDate(date: DateTime) {
        return new NgbDate(
            date.year,
            date.month,
            date.day
        )
    }

    protected readonly toolbar = toolbar
    protected readonly environment = environment
    protected readonly DateTime = DateTime
}
