export class BookingsFeedbackReportData {
    public firstName: string | null
    public lastName: string | null
    public emailAddress: string | null
    public startTime: Date
    public rating: number
    public food: number | null
    public service: number | null
    public atmosphere: number | null
    public comment: string | null
    public isRedirectedExternally: boolean
    public feedbackDateCreated: Date

    constructor(
        firstName: string | null,
        lastName: string | null,
        emailAddress: string | null,
        startTime: Date,
        rating: number,
        food: number | null,
        service: number | null,
        atmosphere: number | null,
        comment: string,
        isRedirectedExternally: boolean,
        feedbackDateCreated: Date
    ) {
        this.firstName = firstName
        this.lastName = lastName
        this.emailAddress = emailAddress
        this.startTime = startTime
        this.rating = rating
        this.food = food
        this.service = service
        this.atmosphere = atmosphere
        this.comment = comment
        this.isRedirectedExternally = isRedirectedExternally
        this.feedbackDateCreated = feedbackDateCreated
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`
    }
}
