import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

type OrdinalPeriod = 'day-of-month' | 'week-of-month'

@Pipe({
    name: 'ordinalDate',
})
export class OrdinalDatePipe implements PipeTransform {

    constructor(private datePipe: DatePipe) { }

    transform(value: any, format: string, ordinalPeriod: OrdinalPeriod): string | null {
        let ordinalFormat: string
        switch (ordinalPeriod) {
        case 'day-of-month':
            ordinalFormat = 'd'
            break
        case 'week-of-month':
            ordinalFormat = 'W'
            break
        }
        const ordinal = this.ordinal(Number(this.datePipe.transform(value, ordinalFormat)))
        const args = format.replace(/o/g, `'${ordinal}'`)
        return this.datePipe.transform(value, args)
    }

    private ordinal(input: number): string {
        const n: number = input % 100
        return n === 0 ? 'th' : (n < 11 || n > 13) ?
            ['st', 'nd', 'rd', 'th'][Math.min((n - 1) % 10, 3)] : 'th'
    }
}
