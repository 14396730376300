import { Injectable } from '@angular/core'
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'

@Injectable()
export class GBLocaleDateParserFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDate | null {
        const parts = value.split('/')
        if (parts.length !== 3) {
            return null
        }
        const day = parseInt(parts[0], 10)
        const month = parseInt(parts[1], 10)
        const year = parseInt(parts[2], 10)
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return null
        }
        return new NgbDate(year, month, day)
    }

    format(date: NgbDate | null): string {
        if (date === null) {
            return ''
        }
        const nativeDate = this.makeDate(date)
        const day = nativeDate.getDate().toString().padStart(2, '0')
        const month = (nativeDate.getMonth() + 1).toString().padStart(2, '0')
        const year = nativeDate.getFullYear()
        return `${day}/${month}/${year}`
    }

    private makeDate(date: NgbDate): Date {
        return new Date(date.year, date.month - 1, date.day)
    }

    private makeNgbDate(date: Date): NgbDate {
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }
}
