import { AnswerDTO } from '@services/DTO/AnswerDTO'
import { BookingFeedbackDTO } from '@services/DTO/BookingFeedbackDTO'
import { BookingFeedbackRequestDTO } from '@services/DTO/BookingFeedbackRequestDTO'
import { BookingReminderDTO } from '@services/DTO/BookingReminderDTO'
import { BookingSourceDTO } from '@services/DTO/BookingSourceDTO'
import { BookingStatusDTO } from '@services/DTO/BookingStatusDTO'
import { CancellationDTO } from '@services/DTO/CancellationDTO'
import { ContactDTO } from './ContactDTO'
import { CustomerDTO } from '@services/DTO/CustomerDTO'
import { DepositDTO } from '@services/DTO/DepositDTO'

export class BookingDTO {
    constructor(
        public id: string,
        public organisationId: string,
        public tableIds: string[],
        public contact: ContactDTO,
        public customer: CustomerDTO | null,
        public size: number,
        public start: string,
        public durationMinutes: number,
        public notes: string | null,
        public merchantNotes: string | null,
        public agreedToMarketing: boolean,
        public agreedToTerms: boolean,
        public paymentCustomerId: string | null,
        public source: BookingSourceDTO,
        public reminders: BookingReminderDTO[],
        public statusEvents: BookingStatusDTO[],
        public feedbackRequests: BookingFeedbackRequestDTO[],
        public feedback: BookingFeedbackDTO[],
        public reasonId: string | null,
        public eventId: string | null,
        public answers: AnswerDTO[],
        public cancellation: CancellationDTO | null,
        public deposit: DepositDTO | null,
        public sendFeedbackRequest: boolean,
        public requiresWheelchairAccess: boolean,
        public requiresDogFriendly: boolean,
        public lockedToTables: boolean,
        public dateVoided: string | null
    ) { }
}
