import { Helper } from '@app/helpers/utilities/helper'
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TokenRepositoryService } from '@services/token-repository.service'
import { environment } from '@environments/environment'

@Injectable()
export class AuthProvidingInterceptor implements HttpInterceptor {
    constructor(private tokenRepositoryService: TokenRepositoryService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let headers = request.headers as HttpHeaders
        const isAuthAllowedDomain = Helper.matchString(request.url, environment.authAllowedDomains)
        if (!isAuthAllowedDomain) {
            return next.handle(request)
        }
        if (headers.has('Authorization')) {
            return next.handle(request)
        }
        if (!this.tokenRepositoryService.hasTokens()) {
            return next.handle(request)
        }
        const httpMethodSupportsAuthorisation = request.method !== 'OPTIONS'
        if (!httpMethodSupportsAuthorisation) {
            return next.handle(request)
        }
        headers = headers.append(
            'Authorization',
            `Bearer ${this.tokenRepositoryService.getAccessToken()}`
        )
        return next.handle(request.clone({ headers }))
    }
}
