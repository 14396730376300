import { DateTime } from 'luxon'
import { NgbDateAdapter, NgbDateStruct , NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'

export class NGBDateTimeAdapter implements NgbTimeAdapter<Date> {

    fromModel(value: Date | null): NgbTimeStruct | null {
        if (!value) {
            return null
        }
        return {
            hour: value.getHours(),
            minute: value.getMinutes(),
            second: value.getSeconds(),
        }
    }

    toModel(time: NgbTimeStruct | null): Date | null {
        if (!time) {
            return null
        }
        const date = new Date()
        date.setHours(time.hour)
        date.setMinutes(time.minute)
        date.setSeconds(time.second)
        return date
    }
}

export class DateTimeDateAdapter implements NgbDateAdapter<DateTime> {

    constructor(
        private timeZone: string
    ) { }

    fromModel(value: DateTime | null): NgbDateStruct | null {
        if (!value) {
            return null
        }
        return {
            year: value.year,
            month: value.month,
            day: value.day,
        }
    }

    toModel(date: NgbDateStruct | null): DateTime | null {
        if (!date) {
            return null
        }
        return DateTime.fromObject({
            year: date.year,
            month: date.month,
            day: date.day,
        }, {
            zone: this.timeZone,
        })
    }
}

export class TimeZoneRelativeDateTimeTimeAdapter implements NgbTimeAdapter<DateTime> {

    constructor(
        private timeZone: string
    ) { }

    fromModel(value: DateTime | null): NgbTimeStruct | null {
        if (!value) {
            return null
        }
        const time = value.setZone(this.timeZone)
        return {
            hour: time.hour,
            minute: time.minute,
            second: time.second,
        }
    }

    toModel(time: NgbTimeStruct): DateTime {
        const date = DateTime.local()
        return date.setZone(this.timeZone).set({
            hour: time.hour,
            minute: time.minute,
            second: time.second,
        })
    }
}
