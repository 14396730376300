import { Business } from '@app/domain/Business'
import { DTOAdapter } from '@services/DTOAdapter'
import { DateTime } from 'luxon'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'
import { Organisation } from '@app/domain/Organisation'
import { Schedule } from '@app/domain/Schedule'
import { ScheduleDTO } from '@services/DTO/ScheduleDTO'
import { ScheduleException } from '@app/domain/ScheduleException'
import { ScheduleExceptionDTO } from '@services/DTO/ScheduleExceptionDTO'
import { ScheduleRule } from '@app/domain/ScheduleRule'
import { ScheduleRuleDTO } from '@services/DTO/ScheduleRuleDTO'
import { Service } from '@app/domain/Service'
import { ServiceDTO } from '@services/DTO/ServiceDTO'
import { Venue } from '@app/domain/Venue'
import { VenueClosure } from '@app/domain/VenueClosure'
import { VenueClosureDTO } from '@services/DTO/VenueClosureDTO'
import { VenueSchedule } from '@app/domain/VenueSchedule'
import { VenueScheduleDTO } from '@services/DTO/VenueScheduleDTO'
import { compare } from 'fast-json-patch'
import { environment } from '@environments/environment'

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) {}

    addSchedule(
        organisation: Organisation,
        schedule: Schedule
    ): Observable<Schedule> {
        const path = `/organisation/${organisation.id}/schedule`
        const url = new URL(path, environment.apiBaseURL)
        const dto = this.dtoAdapter.adaptSchedule(schedule)
        return this.http.post<ScheduleDTO>(url.toString(), dto)
            .pipe(
                map(dto => this.dtoAdapter.adaptScheduleDto(dto))
            )
    }

    updateSchedule(
        organisation: Organisation,
        schedule: Schedule,
        updatedSchedule: Schedule
    ): Observable<Schedule> {
        const path = `/organisation/${organisation.id}/schedule/${schedule.id}`
        const url = new URL(path, environment.apiBaseURL)
        const dto = this.dtoAdapter.adaptSchedule(schedule)
        const updatedDto = this.dtoAdapter.adaptSchedule(updatedSchedule)
        const patch = compare(dto, updatedDto)
        return this.http.patch<ScheduleDTO>(url.toString(), patch)
            .pipe(
                map(dto => this.dtoAdapter.adaptScheduleDto(dto))
            )
    }

    deleteSchedule(
        organisation: Organisation,
        schedule: Schedule
    ): Observable<void> {
        const path = `/organisation/${organisation.id}/schedule/${schedule.id}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.delete(url.toString())
            .pipe(
                map(() => void 0)
            )
    }

    addService(
        organisation: Organisation,
        schedule: Schedule,
        service: Service
    ): Observable<Service> {
        const path = `/organisation/${organisation.id}/schedule/${schedule.id}/service`
        const url = new URL(path, environment.apiBaseURL)
        const dto = this.dtoAdapter.adaptService(service)
        return this.http.post<ServiceDTO>(url.toString(), dto)
            .pipe(
                map(dto => this.dtoAdapter.adaptServiceDto(dto))
            )
    }

    updateService(
        organisation: Organisation,
        schedule: Schedule,
        service: Service,
        updatedService: Service
    ): Observable<Service> {
        const path = `/organisation/${organisation.id}/schedule/${schedule.id}/service/${service.id}`
        const url = new URL(path, environment.apiBaseURL)
        const dto = this.dtoAdapter.adaptService(service)
        const updatedDto = this.dtoAdapter.adaptService(updatedService)
        const patch = compare(dto, updatedDto)
        return this.http.patch<ServiceDTO>(url.toString(), patch)
            .pipe(
                map(dto => this.dtoAdapter.adaptServiceDto(dto))
            )
    }

    deleteService(
        organisation: Organisation,
        schedule: Schedule,
        service: Service
    ): Observable<void> {
        const path = `/organisation/${organisation.id}/schedule/${schedule.id}/service/${service.id}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.delete(url.toString())
            .pipe(
                map(() => void 0)
            )
    }

    addRule(
        organisation: Organisation,
        schedule: Schedule,
        service: Service,
        rule: ScheduleRule
    ): Observable<ScheduleRule> {
        const path =
            `/organisation/${organisation.id}` +
            `/schedule/${schedule.id}` +
            `/service/${service.id}` +
            '/rule'
        const url = new URL(path, environment.apiBaseURL)
        const dto = this.dtoAdapter.adaptScheduleRule(rule)
        return this.http.post<ScheduleRuleDTO>(url.toString(), dto)
            .pipe(
                map(dto => this.dtoAdapter.adaptScheduleRuleDto(dto))
            )
    }

    updateRule(
        organisation: Organisation,
        schedule: Schedule,
        service: Service,
        rule: ScheduleRule
    ): Observable<ScheduleRule> {
        const existingRule = schedule.rules.find(existingRule => existingRule.id === rule.id)!
        const existingDto = this.dtoAdapter.adaptScheduleRule(existingRule)
        const dto = this.dtoAdapter.adaptScheduleRule(rule)
        const patch = compare(existingDto, dto)
        const path =
            `/organisation/${organisation.id}` +
            `/schedule/${schedule.id}` +
            `/service/${service.id}` +
            `/rule/${rule.id}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.patch<ScheduleRuleDTO>(url.toString(), patch)
            .pipe(
                map(dto => this.dtoAdapter.adaptScheduleRuleDto(dto))
            )
    }

    deleteRule(
        organisation: Organisation,
        schedule: Schedule,
        service: Service,
        rule: ScheduleRule
    ): Observable<void> {
        const path =
            `/organisation/${organisation.id}` +
            `/schedule/${schedule.id}` +
            `/service/${service.id}` +
            `/rule/${rule.id}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.delete(url.toString())
            .pipe(
                map(() => void 0)
            )
    }

    addException(
        organisation: Organisation,
        schedule: Schedule,
        exception: ScheduleException
    ): Observable<ScheduleException> {
        const path = `/organisation/${organisation.id}/schedule/${schedule.id}/exception`
        const url = new URL(path, environment.apiBaseURL)
        const dto = this.dtoAdapter.adaptScheduleException(exception)
        return this.http.post<ScheduleExceptionDTO>(url.toString(), dto)
            .pipe(
                map(dto => this.dtoAdapter.adaptScheduleExceptionDto(dto))
            )
    }

    updateException(
        organisation: Organisation,
        schedule: Schedule,
        exception: ScheduleException
    ): Observable<ScheduleException> {
        const existingException = schedule.exceptions.find(existingException => {
            return existingException.id === exception.id
        })!
        const existingDto = this.dtoAdapter.adaptScheduleException(existingException)
        const dto = this.dtoAdapter.adaptScheduleException(exception)
        const patch = compare(existingDto, dto)
        const path = `/organisation/${organisation.id}/schedule/${schedule.id}/exception/${exception.id}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.patch<ScheduleExceptionDTO>(url.toString(), patch)
            .pipe(
                map(dto => this.dtoAdapter.adaptScheduleExceptionDto(dto))
            )
    }

    deleteException(
        organisation: Organisation,
        schedule: Schedule,
        exception: ScheduleException
    ): Observable<void> {
        const path = `/organisation/${organisation.id}/schedule/${schedule.id}/exception/${exception.id}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.delete(url.toString())
            .pipe(
                map(() => void 0)
            )
    }

    getVenueSchedule(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        startDate: Date,
        endDate: Date
    ): Observable<VenueSchedule> {
        const startDateString = DateTime.fromJSDate(startDate)
            .setZone(venue.timeZone)
            .toISODate()!
        const endDateString = DateTime.fromJSDate(endDate)
            .setZone(venue.timeZone)
            .toISODate()!
        const params = new HttpParams()
            .set('startDate', startDateString)
            .set('endDate', endDateString)
        const path = `/organisation/${organisation.id}
/business/${business.id}
/venue/${venue.id}
/schedule\
?${params.toString()}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.get<VenueScheduleDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptVenueScheduleDto(dto))
            )
    }

    addVenueClosure(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        closure: VenueClosure
    ): Observable<VenueClosure> {
        const path = `/organisation/${organisation.id}/business/${business.id}/venue/${venue.id}/closure`
        const url = new URL(path, environment.apiBaseURL)
        const dto = this.dtoAdapter.adaptVenueClosure(closure)
        return this.http.post<VenueClosureDTO>(url.toString(), dto)
            .pipe(
                map(dto => this.dtoAdapter.adaptVenueClosureDto(dto))
            )
    }

    removeVenueClosure(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        closure: VenueClosure
    ): Observable<void> {
        const path = `/organisation/${organisation.id}/business/${business.id}/venue/${venue.id}/closure/${closure.id}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.delete(url.toString())
            .pipe(
                map(() => void 0)
            )
    }
}
