import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { Schedule } from '@app/domain/Schedule'
import { DateTimePipe } from '@app/features/shared/pipes/date-time.pipe'
import { DateTime } from 'luxon'

@Pipe({
    name: 'scheduleDateRange',
})
export class ScheduleDateRangePipe implements PipeTransform {

    constructor(
        private datePipe: DateTimePipe
    ) { }

    transform(
        schedule: Schedule,
        format: 'inline' | 'block' = 'block',
        timeZone: string
    ): string {
        if (schedule.dateRanges.length === 0) {
            return 'Always Active'
        }
        return schedule.dateRanges
            .map(dateRange => this.transformDateRange(dateRange.start, dateRange.end, timeZone))
            .join(format === 'block' ? '\n' : ', ')
    }

    private transformDateRange(start: Date | null, end: Date | null, timeZone: string): string {
        if (start === null && end === null) {
            return 'Always Active'
        }
        if (start === null) {
            return `Until ${this.datePipe.transform(end, timeZone, DateTime.DATE_SHORT)}`
        }
        if (end === null) {
            return `From ${this.datePipe.transform(start, timeZone, DateTime.DATE_SHORT)}`
        }
        return `${this.datePipe.transform(start, timeZone, DateTime.DATE_SHORT)}
         - ${this.datePipe.transform(end, timeZone, DateTime.DATE_SHORT)}`
    }
}
