export class BillingDetailsDTO {

    constructor(
        public inService: boolean,
        public needsToUpgradeTrial: boolean,
        public nextBillingAmount: string,
        public nextBillingAmountCurrencyCode: string,
        public nextBillingDate: Date | null,
        public addons: string[]
    ) { }
}
