<app-spinner-overlay
    [showSpinner]="isEditingBooking"
>
</app-spinner-overlay>
<div class="modal-header">
    <div class="hstack gap-2 flex-wrap">
        <h4 class="mb-0">
            Booking on {{ booking.start | dateTime : venue.timeZone : DateTime.DATE_MED_WITH_WEEKDAY }}
        </h4>
        <app-booking-status-badge
            [statusType]="booking.status().type"
        >
            {{ booking.status().type | bookingStatusName }}
        </app-booking-status-badge>
        <app-booking-source-badge
            *ngIf="diaryPreferencesService.showBookingSource$ | async"
            [source]="booking.source"
        >
        </app-booking-source-badge>
    </div>
    <button
        type="button"
        class="btn-close"
        data-testid="booking-dismiss-button"
        (click)="dismissClicked()"
        ngbAutofocus
    >
    </button>
</div>
<div class="modal-body p-0">
    <ul class="nav nav-pills px-3 px-md-4 tabbed">
        <li class="nav-item">
            <button
                class="nav-link"
                [ngClass]="page === 'details' ? 'active' : ''"
                (click)="detailsTabSelected()"
            >
                Details
            </button>
        </li>
        <li class="nav-item">
            <button
                class="nav-link"
                [ngClass]="page === 'customer' ? 'active' : ''"
                (click)="customerTabSelected()"
            >
                Customer
            </button>
        </li>
        <li class="nav-item">
            <button
                class="nav-link"
                [ngClass]="page === 'payments' ? 'active' : ''"
                (click)="paymentsTabSelected()"
            >
                <span class="position-relative">
                    Payments
                    @if (booking.canChargeCancellationFee()) {
                        <div
                            class="position-absolute d-none d-lg-flex"
                            style="right: -8px; top: -8px;"
                        >
                            <div
                                class="rounded bg-warning top-0 hstack justify-content-center"
                                [style.width.px]="20"
                                [style.height.px]="20"
                            >
                                <img
                                    ngSrc="assets/credit-card/normal_white.svg"
                                    width="12"
                                    height="12"
                                    alt="Notification"
                                />
                            </div>
                        </div>
                    }
                </span>
            </button>
        </li>
        <li class="nav-item">
            <button
                class="nav-link"
                [ngClass]="page === 'history' ? 'active' : ''"
                (click)="historyTabSelected()"
            >
                History
            </button>
        </li>
    </ul>
    <hr class="m-0"/>
    <div>
        <div
            *ngIf="page === 'details'"
        >
            <app-booking-details
                [business]="business"
                [venue]="venue"
                [booking]="booking"
                [otherBookings]="otherBookings"
                [schedule]="schedule"
                (bookingChanged)="bookingChanged.emit($event)"
                (customerSelected)="customerSelected.emit($event)"
                (paymentsSelected)="paymentsTabSelected()"
            >
            </app-booking-details>
        </div>
        <div
            *ngIf="page === 'customer'"
        >
            <app-contact-details
                [business]="business"
                [venue]="venue"
                [booking]="booking"
                (bookingChanged)="bookingChanged.emit($event)"
                (customerSelected)="customerSelected.emit($event)"
            >
            </app-contact-details>
        </div>
        <div
            *ngIf="page === 'payments'"
        >
            <app-booking-payments
                [business]="business"
                [venue]="venue"
                [booking]="booking"
                (bookingChanged)="bookingChanged.emit($event)"
            >
            </app-booking-payments>
        </div>
        <div
            *ngIf="page === 'history'"
        >
            <app-booking-history
                [organisation]="organisation"
                [venue]="venue"
                [booking]="booking"
            >
            </app-booking-history>
        </div>
        <div
            *ngIf="false"
            class="row"
        >
            <h5>
                Charge Customer
            </h5>
            <button
                class="btn btn-outline-secondary"
            >
                Charge Customer
            </button>
        </div>
    </div>
</div>
<div
    *ngIf="page === 'details'"
    class="modal-footer"
>
    <div
        class="d-flex gap-2 flex-column flex-md-row flex-grow-1"
    >
        <div
            class="hstack gap-3 me-md-auto flex-grow-1 flex-md-grow-0 order-1 order-md-0"
        >
            <ng-container
                *ngIf="(viewModel.showCancelBookingButton || viewModel.showCancelAndChargeButton || viewModel.showCancelAndRefundDepositButton) && viewModel.canVoidBooking"
            >
                <div
                    class="btn-group"
                    ngbDropdown
                    container="body"
                >
                    <ng-container
                        [ngTemplateOutlet]="cancelButtonVariations"
                    >
                    </ng-container>
                    <button
                        type="button"
                        class="btn btn-outline-danger dropdown-toggle dropdown-toggle-split"
                        ngbDropdownToggle
                    >
                        <span class="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <div
                        class="dropdown-menu"
                        ngbDropdownMenu
                    >
                        <button
                            class="dropdown-item fw-semibold"
                            (click)="voidBooking(confirmVoidModalBody)"
                            ngbDropdownItem
                        >
                            Void Booking
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container
                *ngIf="!viewModel.canVoidBooking && (viewModel.showCancelBookingButton || viewModel.showCancelAndChargeButton || viewModel.showCancelAndRefundDepositButton)"
                [ngTemplateOutlet]="cancelButtonVariations"
            >
            </ng-container>
            <button
                *ngIf="viewModel.canVoidBooking && !(viewModel.showCancelBookingButton || viewModel.showCancelAndChargeButton || viewModel.showCancelAndRefundDepositButton)"
                class="btn btn-outline-danger me-md-auto flex-grow-1"
                (click)="voidBooking(confirmVoidModalBody)"
            >
                Void Booking
            </button>
            <button
                *ngIf="viewModel.showNoShowBookingButton"
                class="btn btn-outline-secondary"
                (click)="noShowBookingWithoutCharging(confirmNoShowModalBody)"
            >
                Mark as No Show
            </button>
            <button
                *ngIf="viewModel.showNoShowAndChargeButton"
                class="btn btn-outline-secondary me-md-auto flex-grow-1"
                (click)="noShowBookingWithCharge(confirmNoShowChargeModalBody)"
            >
                Mark as No Show
            </button>
            <button
                *ngIf="viewModel.showNoShowAndRefundDepositButton"
                class="btn btn-outline-secondary me-md-auto flex-grow-1"
                (click)="NoShowBookingAndRefundDeposit(confirmNoShowRefundDepositModalBody)"
            >
                Mark as No Show
            </button>
        </div>
        <div
            class="hstack gap-3 ms-md-auto order-0 order-md-1"
        >
            <button
                *ngIf="viewModel.canRejectBooking"
                class="btn btn-outline-danger me-md-auto flex-grow-1"
                (click)="rejectBooking(rejectBookingModalBody)"
            >
                Reject
            </button>
            <button
                *ngIf="viewModel.canAcceptBooking"
                class="btn btn-primary me-md-auto flex-grow-1"
                (click)="acceptBooking()"
            >
                Accept
            </button>
            <button
                *ngIf="viewModel.canUnseatParty && !viewModel.canSetPartyToWaiting"
                class="btn btn-outline-info ms-md-auto flex-grow-1"
                (click)="viewModel.unseatBooking()"
            >
                Unseat Party
            </button>
            <div
                *ngIf="viewModel.canUnseatParty && viewModel.canSetPartyToWaiting"
                class="btn-group ms-md-auto flex-grow-1"
                ngbDropdown
                container="body"
            >
                <button
                    type="button"
                    class="btn btn-outline-info"
                    (click)="viewModel.unseatBooking()"
                >
                    Unseat Party
                </button>
                <button
                    type="button"
                    class="btn btn-outline-info dropdown-toggle dropdown-toggle-split"
                    ngbDropdownToggle
                >
                    <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                    *ngIf="viewModel.canSetPartyToWaiting"
                    class="dropdown-menu"
                    ngbDropdownMenu
                >
                    <button
                        class="dropdown-item fw-semibold"
                        (click)="viewModel.setPartyToWaiting()"
                        ngbDropdownItem
                    >
                        Wait at Bar
                    </button>
                </div>
            </div>
            <div
                *ngIf="viewModel.canPartiallySeatParty"
                class="btn-group ms-md-auto flex-grow-1"
                ngbDropdown
                container="body"
            >
                @if (viewModel.canSeatParty) {
                    <button
                        type="button"
                        class="btn btn-info"
                        data-testid="seat-party-button"
                        (click)="viewModel.seatBooking()"
                    >
                        Seat Party
                    </button>
                }
                <button
                    type="button"
                    class="btn btn-info dropdown-toggle dropdown-toggle-split"
                    ngbDropdownToggle
                >
                    <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                    class="dropdown-menu"
                    ngbDropdownMenu
                >
                    @if (viewModel.canSetPartyToWaiting) {
                        <button
                            class="dropdown-item fw-semibold"
                            (click)="viewModel.setPartyToWaiting()"
                            ngbDropdownItem
                        >
                            Wait at Bar
                        </button>
                    }
                    <button
                        class="dropdown-item fw-semibold"
                        (click)="viewModel.partiallySeatBooking()"
                        ngbDropdownItem
                    >
                        Partially Seat Party
                    </button>
                </div>
            </div>
            <button
                *ngIf="viewModel.canSeatParty && !viewModel.canPartiallySeatParty"
                type="button"
                class="btn btn-info"
                data-testid="seat-party-button"
                (click)="viewModel.seatBooking()"
            >
                Seat Party
            </button>
            <button
                *ngIf="viewModel.canFinishBooking"
                class="btn btn-success ms-md-auto flex-grow-1"
                (click)="viewModel.finishBooking()"
            >
                Finish Booking
            </button>
            <button
                *ngIf="viewModel.canReseatParty"
                class="btn btn-success ms-md-auto flex-grow-1"
                (click)="viewModel.reseatBooking()"
            >
                Reseat Party
            </button>
            @if (viewModel.canUndoCancellation) {
                <button
                    class="btn btn-outline-info ms-md-auto flex-grow-1"
                    (click)="viewModel.undoCancellation()"
                >
                    Undo Cancellation
                </button>
            }
        </div>
    </div>
</div>

<ng-template #cancelButtonVariations>
    <button
        *ngIf="viewModel.showCancelBookingButton"
        class="btn btn-outline-danger"
        (click)="cancelBookingWithoutCharging(confirmCancelModalBody)"
    >
        Cancel Booking
    </button>
    <button
        *ngIf="viewModel.showCancelAndChargeButton"
        class="btn btn-outline-danger"
        (click)="cancelAndChargeBooking(confirmCancelChargeModalBody)"
    >
        Cancel Booking
    </button>
    <button
        *ngIf="viewModel.showCancelAndRefundDepositButton"
        class="btn btn-outline-danger"
        (click)="cancelAndRefundBookingDeposit(confirmCancelRefundDepositModalBody)"
    >
        Cancel Booking
    </button>
</ng-template>

<ng-template #rejectBookingModalBody>
    <span>
        Are you sure you want to reject this booking?
        <br/>
        This action cannot be undone.
    </span>
</ng-template>

<ng-template #confirmCancelModalBody>
    <span>
        Are you sure you want to cancel this booking?
        <br/>
        This action cannot be undone.
    </span>
</ng-template>

<ng-template #confirmNoShowModalBody>
    <span>
        Are you sure you want to mark this booking as a No Show?
        <br/>
        This action cannot be undone.
    </span>
</ng-template>

<ng-template #confirmNoShowChargeModalBody>
    <div class="mb-3">
        <ng-container
            *ngIf="booking.cancellation?.charge(); let charge"
        >
            <p
                *ngIf="charge.lowerBound && !charge.upperBound"
            >
                This booking has a cancellation charge pending because it's party size is
                equal to or greater than
                {{ charge.lowerBound | i18nPlural : partySizePluralMapping }}.
            </p>
            <p
                *ngIf="!charge.lowerBound && charge.upperBound"
            >
                This booking has a cancellation charge pending because it's party size is
                equal to or less than
                {{ charge.upperBound | i18nPlural : partySizePluralMapping }}.
            </p>
            <p
                *ngIf="charge.lowerBound && charge.upperBound"
            >
                This booking has a cancellation charge pending because it's party size is
                between
                {{ charge.lowerBound | i18nPlural : partySizePluralMapping }}
                and
                {{ charge.upperBound | i18nPlural : partySizePluralMapping }}.
            </p>
            <p>
                Would you like to charge the
                <span class="fw-bold">
                    {{ null | currencyUnit: charge.unitAmount : 'GBP' }}
                    <ng-container *ngIf="charge.isPerCover">
                        per person
                    </ng-container>
                </span>
                cancellation fee when cancelling this booking?
            </p>
        </ng-container>
    </div>
</ng-template>

<ng-template #confirmNoShowRefundDepositModalBody>
    <div class="mb-3">
        <p
            *ngIf="viewModel.hasDepositRefundCutOffPassed"
        >
            This booking's deposit refund cut-off time has passed, but you can still refund the deposit if you wish.
            <br/>
            Would you like to refund the deposit when marking this booking as a no-show?
        </p>
        <p
            *ngIf="!viewModel.hasDepositRefundCutOffPassed"
        >
            Would you like to refund the deposit when marking this booking as a no-show?
        </p>
    </div>
</ng-template>

<ng-template #confirmCancelChargeModalBody>
    <div class="mb-3">
        <ng-container
            *ngIf="booking.cancellation?.charge(); let charge"
        >
            <p
                *ngIf="charge.lowerBound && !charge.upperBound"
            >
                This booking has a cancellation charge pending because it's party size is
                equal to or greater than
                {{ charge.lowerBound | i18nPlural : partySizePluralMapping }}.
            </p>
            <p
                *ngIf="!charge.lowerBound && charge.upperBound"
            >
                This booking has a cancellation charge pending because it's party size is
                equal to or less than
                {{ charge.upperBound | i18nPlural : partySizePluralMapping }}.
            </p>
            <p
                *ngIf="charge.lowerBound && charge.upperBound"
            >
                This booking has a cancellation charge pending because it's party size is
                between
                {{ charge.lowerBound | i18nPlural : partySizePluralMapping }}
                and
                {{ charge.upperBound | i18nPlural : partySizePluralMapping }}.
            </p>
            <p>
                Would you like to charge the
                <span class="fw-bold">
                    {{ null | currencyUnit: charge.unitAmount : 'GBP' }}
                    <ng-container *ngIf="charge.isPerCover">
                        per person
                    </ng-container>
                </span>
                cancellation fee when cancelling this booking?
            </p>
        </ng-container>
    </div>
</ng-template>

<ng-template #confirmCancelRefundDepositModalBody>
    <div class="mb-3">
        <p
            *ngIf="viewModel.hasDepositRefundCutOffPassed"
        >
            This booking's deposit refund cut-off time has passed, but you can still refund the deposit if you wish.
            <br/>
            Would you like to refund the deposit when cancelling this booking?
        </p>
        <p
            *ngIf="!viewModel.hasDepositRefundCutOffPassed"
        >
            Would you like to refund the deposit when cancelling this booking?
        </p>
    </div>
</ng-template>

<ng-template #confirmVoidModalBody>
    <span>
        Are you sure you want to void this booking?
        <br/>
        <span class="fw-semibold">
            Voiding a booking will remove it from the diary and not count towards any reports.
        </span>
        <br/>
        This action cannot be undone.
    </span>
</ng-template>
