import { DateArrayPipe } from '@app/features/shared/pipes/date-array.pipe'
import { DateComponentUnit } from '@app/domain/DateComponentUnit'
import { DatePipe, I18nPluralPipe } from '@angular/common'
import { DayNamePipe } from '@app/features/shared/pipes/day-name.pipe'
import { EventRecurrence, MonthlyRepetitionType } from '@app/domain/VenueEvent'
import { OrdinalDatePipe } from '@app/features/shared/pipes/ordinal-date.pipe'
import { Pipe, PipeTransform } from '@angular/core'
import { DateTime } from 'luxon'

@Pipe({
    name: 'eventRecurrenceDescription',
})
export class EventRecurrenceDescriptionPipe implements PipeTransform {

    weekOrdinalPluralMapping = {
        '=1': 'Every week',
        'other': 'Every # weeks',
    }
    monthOrdinalPluralMapping = {
        '=1': 'Every month',
        'other': 'Every # months',
    }

    constructor(
        private datePipe: DatePipe,
        private dateArrayPipe: DateArrayPipe,
        private dayNamePipe: DayNamePipe,
        private ordinalDatePipe: OrdinalDatePipe,
        private pluralPipe: I18nPluralPipe
    ) { }

    transform(
        recurrence: EventRecurrence,
        format: 'repetition' | 'times' = 'repetition',
        timeZone: string
    ): string {
        switch (format) {
        case 'repetition':
            return [
                this.makeDescriptionForRepeatsEvery(recurrence, timeZone),
                this.makeDescriptionForRepeatsOnDaysOfWeek(recurrence),
                this.makeDescriptionForRepeatsOnDayOfMonth(recurrence, timeZone),
                this.makeDescriptionForRepeatsOnDayOfWeek(recurrence, timeZone),
            ]
                .filter(description => description !== null)
                .join(', ')
        case 'times':
            return this.makeDescriptionForTime(recurrence, timeZone)
        }
    }

    private makeDescriptionForRepeatsEvery(recurrence: EventRecurrence, timeZone: string) {
        if (recurrence.dateComponent === null) {
            return 'On ' +
                this.dateArrayPipe.transform(recurrence.startDates, timeZone, DateTime.DATE_SHORT, ', ')
        }
        if (recurrence.dateComponent.unit === DateComponentUnit.DAY_OF_WEEK) {
            return this.pluralPipe.transform(recurrence.dateComponent.value, this.weekOrdinalPluralMapping)
        } else if (recurrence.dateComponent.unit === DateComponentUnit.DAY_OF_MONTH) {
            return this.pluralPipe.transform(recurrence.dateComponent.value, this.monthOrdinalPluralMapping)
        }
        return null
    }

    private makeDescriptionForRepeatsOnDaysOfWeek(recurrence: EventRecurrence) {
        if (!recurrence.weeklyRepeatsOnDaysOfWeek) {
            return null
        }
        return 'on '
            + recurrence.weeklyRepeatsOnDaysOfWeek
                .map(dayOfWeek => this.dayNamePipe.transform(dayOfWeek, 'long'))
                .join(', ')
    }

    private makeDescriptionForRepeatsOnDayOfMonth(recurrence: EventRecurrence, timeZone: string) {
        if (recurrence.monthlyRepetitionType !== MonthlyRepetitionType.DayOfMonth) {
            return null
        }
        const startDate = recurrence.earliestStartDate()
        return 'on the '
            + this.ordinalDatePipe.transform(startDate, 'do', 'day-of-month')
            + ' day of the month'
    }

    private makeDescriptionForRepeatsOnDayOfWeek(recurrence: EventRecurrence, timeZone: string) {
        if (recurrence.monthlyRepetitionType !== MonthlyRepetitionType.DayOfWeek) {
            return null
        }
        const startDate = recurrence.earliestStartDate()
        const startDateDayOfWeek = startDate.getDay()
        return 'each '
            + this.ordinalDatePipe.transform(startDate, 'Wo', 'week-of-month')
            + ' '
            + this.dayNamePipe.transform(startDateDayOfWeek, 'long') + ' of the month'
    }

    private makeDescriptionForTime(recurrence: EventRecurrence, timeZone: string) {
        const startTime = recurrence.startTime ?? this.datePipe.transform(recurrence.startTime, timeZone, 'shortTime')
        const endTime = recurrence.endTime ?? this.datePipe.transform(recurrence.endTime, timeZone, 'shortTime')
        if (recurrence.startTime === null && recurrence.endTime === null) {
            return ' all day'
        } else if (recurrence.startTime !== null && recurrence.endTime === null) {
            return ' from ' + startTime
        } else if (recurrence.startTime === null && recurrence.endTime !== null) {
            return ' until ' + endTime
        } else {
            return ' from ' + startTime + ' until ' + endTime
        }
    }
}
