export class TableDTO {

    constructor(
        public id: string,
        public displayName: string,
        public reservable: boolean,
        public minimumSeats: number,
        public maximumSeats: number,
        public bookingOrder: number,
        public displayOrder: number,
        public floorPlanX: number,
        public floorPlanY: number,
        public floorPlanWidth: number,
        public floorPlanHeight: number,
        public reasonIds: string[],
        public wheelchairAccessible: boolean | null,
        public dogFriendly: boolean | null,
        public dateCreated: string,
        public dateUpdated: string
    ) { }
}
