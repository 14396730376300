<div class="vstack gap-1 align-items-baseline">
    <div class="hstack gap-2 align-items-baseline">
        <div class="hstack gap-2 flex-grow-1">
            <span class="fw-semibold text-truncate">
                {{ booking.name }}
            </span>
            <app-party-size-badge
                class="lh-1"
                [partySize]="booking.size"
            >
            </app-party-size-badge>
        </div>
        <div class="hstack gap-3 align-items-center">
            <div
                *ngIf="showDetails"
                class="hstack gap-2 align-items-center"
            >
                <span
                    *ngIf="booking.contact.emailAddress"
                    class="badge-secondary"
                >
                    <i class="bi-at"></i>
                </span>
                <span
                    *ngIf="booking.phoneNumber"
                    class="badge-secondary"
                >
                    <i class="bi-telephone"></i>
                </span>
            </div>
            <app-booking-status-badge
                [statusType]="booking.status().type"
            >
                {{ booking.status().type | bookingStatusName }}
            </app-booking-status-badge>
        </div>
    </div>
    <div class="hstack gap-2 justify-content-between">
        <span
            *ngIf="showDate"
        >
            {{ booking.start | dateTime : environment.assumedVenueTimeZone : DateTime.DATE_MED }}
        </span>
        <div
            *ngIf="showLocation"
            class="hstack gap-2 flex-grow-1"
        >
            <span class="">
                {{ area.displayName }}
            </span>
            <span class="">
                {{ booking | tableName : area }}
            </span>
        </div>
        <span class="text-secondary">
            {{ booking.start | dateTime : environment.assumedVenueTimeZone : DateTime.TIME_SIMPLE }} -
            {{ booking.end | dateTime : environment.assumedVenueTimeZone : DateTime.TIME_SIMPLE }}
        </span>
    </div>
</div>
