import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FloorTableViewModel } from '@app/features/shared/components/area-floor/floor-table.view-model'
import { Observable, Subject, takeUntil } from 'rxjs'
import { ScaleLinear } from 'd3'
import { round } from '@popperjs/core/lib/utils/math'

@Component({
    selector: '[app-floor-tables]',
    templateUrl: './floor-tables.component.html',
    styleUrls: ['./floor-tables.component.sass'],
})
export class FloorTablesComponent implements OnInit, OnDestroy {

    @Input() tableViewModels!: FloorTableViewModel[]
    @Input() xScale!: ScaleLinear<number, number>
    @Input() yScale!: ScaleLinear<number, number>
    @Input() mouseEvents$!: Observable<{ event: 'down' | 'up' | 'move', x: number, y: number }>

    draggedTable: FloorTableViewModel | null = null
    draggedEdges: { left: boolean; right: boolean; top: boolean; bottom: boolean } | null = null
    hasMovedTableDuringDrag = false
    hasResizedTableDuringDrag = false
    private onDestroy = new Subject<void>()

    private readonly MIN_WIDTH = 4
    private readonly MIN_HEIGHT = 4

    constructor() { }

    ngOnInit() {
        this.bindMouseDownToDraggedTable()
        this.bindMouseUpToDraggedTable()
        this.bindMouseMoveToDraggedTable()
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    private bindMouseDownToDraggedTable() {
        this.mouseEvents$.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(mouseEvent => {
            if (mouseEvent.event !== 'down') { return }
            if (this.draggedTable) { return }
            const table = this.getTableAtCoordinates(mouseEvent.x, mouseEvent.y)
            if (table) {
                table.startInteraction()
                this.draggedTable = table
                const edges = this.getEdgesNearMouse(table, mouseEvent.x, mouseEvent.y, 5)
                this.draggedEdges = edges
            }
        })
    }

    private bindMouseUpToDraggedTable() {
        this.mouseEvents$.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(mouseEvent => {
            if (mouseEvent.event !== 'up') { return }
            if (!this.draggedTable) { return }
            this.draggedTable.endInteraction()
            if (this.hasMovedTableDuringDrag || this.hasResizedTableDuringDrag) {
                // Moved too much to be considered a click
            } else {
                const table = this.getTableAtCoordinates(mouseEvent.x, mouseEvent.y)
                if (table === this.draggedTable) {
                    this.draggedTable.tableClicked()
                }
            }
            this.draggedTable = null
            this.draggedEdges = null
            this.hasMovedTableDuringDrag = false
            this.hasResizedTableDuringDrag = false
        })
    }

    private bindMouseMoveToDraggedTable() {
        this.mouseEvents$.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(mouseEvent => {
            if (!this.draggedTable) { return }
            if (mouseEvent.event !== 'move') { return }
            const { x, y } = mouseEvent
            if (this.draggedEdges) {
                if (!this.draggedTable.canUpdateCoordinates) { return }
                this.hasResizedTableDuringDrag = true
                const { width, height } = this.draggedTable.dimensions
                const { x: coordX, y: coordY } = this.draggedTable.coordinates
                let newWidth = width
                let newHeight = height
                let newX = coordX
                let newY = coordY
                if (this.draggedEdges.left) {
                    const newLeft = round(this.xScale.invert(x))
                    const proposedWidth = width + (coordX - newLeft)
                    if (proposedWidth < this.MIN_WIDTH) {
                        newWidth = this.MIN_WIDTH
                    } else {
                        newWidth = proposedWidth
                        newX = newLeft
                    }
                }
                if (this.draggedEdges.right) {
                    const newRight = round(this.xScale.invert(x))
                    newWidth = newRight - coordX
                    if (newWidth < this.MIN_WIDTH) {
                        newWidth = this.MIN_WIDTH
                    }
                }
                if (this.draggedEdges.top) {
                    const newTop = round(this.yScale.invert(y))
                    const proposedHeight = height + (coordY - newTop)
                    if (proposedHeight < this.MIN_HEIGHT) {
                        newHeight = this.MIN_HEIGHT
                    } else {
                        newHeight = proposedHeight
                        newY = newTop
                    }
                }
                if (this.draggedEdges.bottom) {
                    const newBottom = round(this.yScale.invert(y))
                    newHeight = newBottom - coordY
                    if (newHeight < this.MIN_HEIGHT) {
                        newHeight = this.MIN_HEIGHT
                    }
                }
                const resizedTable: FloorTableViewModel = {
                    ...this.draggedTable,
                    dimensions: {
                        width: newWidth,
                        height: newHeight,
                    },
                    coordinates: {
                        x: newX,
                        y: newY,
                    },
                }
                const hasOverlap = this.tableViewModels.some(table => {
                    if (table === this.draggedTable) { return false }
                    return this.isOverlapping(resizedTable, table)
                })
                if (hasOverlap) {
                    return
                }
                this.draggedTable.updateDimensions({
                    width: round(newWidth),
                    height: round(newHeight),
                })
                this.draggedTable.updateCoordinates({
                    x: round(newX),
                    y: round(newY),
                })
            } else {
                if (!this.draggedTable.canUpdateCoordinates) { return }
                this.hasMovedTableDuringDrag = true
                const { width, height } = this.draggedTable.dimensions
                const middleOfTable = {
                    x: x - this.xScale(width / 2),
                    y: y - this.yScale(height / 2),
                }
                const inFloorCoordinates = {
                    x: round(this.xScale.invert(middleOfTable.x)),
                    y: round(this.yScale.invert(middleOfTable.y)),
                }
                this.draggedTable.updateCoordinates(inFloorCoordinates)
            }
        })
    }

    private isOverlapping(
        tableA: {
            coordinates: { x: number, y: number },
            dimensions: { width: number, height: number }
        },
        tableB: {
            coordinates: { x: number, y: number },
            dimensions: { width: number, height: number }
        }
    ): boolean {
        return !(
            tableA.coordinates.x + tableA.dimensions.width <= tableB.coordinates.x ||
            tableB.coordinates.x + tableB.dimensions.width <= tableA.coordinates.x ||
            tableA.coordinates.y + tableA.dimensions.height <= tableB.coordinates.y ||
            tableB.coordinates.y + tableB.dimensions.height <= tableA.coordinates.y
        )
    }

    private getEdgesNearMouse(
        table: FloorTableViewModel,
        mouseX: number,
        mouseY: number,
        threshold: number
    ): { left: boolean; right: boolean; top: boolean; bottom: boolean } | null {
        const { width, height } = table.dimensions
        const { x: coordX, y: coordY } = table.coordinates
        const tableLeft = this.xScale(coordX)
        const tableTop = this.yScale(coordY)
        const tableRight = tableLeft + this.xScale(width)
        const tableBottom = tableTop + this.yScale(height)
        const left = {
            x1: tableLeft,
            x2: tableLeft + 8,
        }
        const right = {
            x1: tableRight - 8,
            x2: tableRight,
        }
        const top = {
            y1: tableTop,
            y2: tableTop + 8,
        }
        const bottom = {
            y1: tableBottom - 8,
            y2: tableBottom,
        }
        const nearLeft = this.doesMouseIntersectSide(mouseX, mouseY, left, undefined)
        const nearRight = this.doesMouseIntersectSide(mouseX, mouseY, right, undefined)
        const nearTop = this.doesMouseIntersectSide(mouseX, mouseY, undefined, top)
        const nearBottom = this.doesMouseIntersectSide(mouseX, mouseY, undefined, bottom)
        if (nearLeft || nearRight || nearTop || nearBottom) {
            return {
                left: nearLeft,
                right: nearRight,
                top: nearTop,
                bottom: nearBottom,
            }
        }
        return null
    }

    private doesMouseIntersectSide(
        mouseX: number,
        mouseY: number,
        horizontal?: { x1: number, x2: number },
        vertical?: { y1: number, y2: number }
    ): boolean {
        if (horizontal) {
            if (horizontal.x1 <= mouseX && mouseX <= horizontal.x2) {
                return true
            }
        }
        if (vertical) {
            if (vertical.y1 <= mouseY && mouseY <= vertical.y2) {
                return true
            }
        }
        return false
    }

    private getTableAtCoordinates(x: number, y: number): FloorTableViewModel | null {
        const inFloorCoordinates = {
            x: this.xScale.invert(x),
            y: this.yScale.invert(y),
        }
        return this.tableViewModels.find(table => {
            const { x: tableX, y: tableY } = table.coordinates
            return tableX <= inFloorCoordinates.x
                && inFloorCoordinates.x <= tableX + table.dimensions.width
                && tableY <= inFloorCoordinates.y
                && inFloorCoordinates.y <= tableY + table.dimensions.height
        }) || null
    }
}
