import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingReason } from '@app/domain/BookingReason'
import { Contact } from '@app/domain/Contact'
import { Customer } from '../domain/Customer'
import { DTOAdapter } from '@services/DTOAdapter'
import { DiaryNote } from '@app/domain/DiaryNote'
import {
    EmailMarketingConfiguration,
} from '@app/domain/integration/email-marketing/EmailMarketingConfiguration'
import { Event } from '@app/domain/VenueEvent'
import { Injectable } from '@angular/core'
import { Question } from '@app/domain/Question'
import { Schedule } from '@app/domain/Schedule'
import { ScheduleRule } from '@app/domain/ScheduleRule'
import { Service } from '@app/domain/Service'
import { TableCombination } from '@app/domain/TableCombination'
import { User } from '@app/domain/User'
import { Venue } from '@app/domain/Venue'
import { VenueClosure } from '../domain/VenueClosure'
import { Voucher } from '@app/domain/Voucher'

@Injectable({
    providedIn: 'root',
})
export class ModelCloningService {

    constructor(
        private dtoAdapter: DTOAdapter
    ) { }

    cloneArea(area: Area): Area {
        const dto = this.dtoAdapter.adaptArea(area)
        return this.dtoAdapter.adaptAreaDto(dto)
    }

    cloneVenue(venue: Venue): Venue {
        const dto = this.dtoAdapter.adaptVenue(venue)
        return this.dtoAdapter.adaptVenueDto(dto)
    }

    cloneQuestion(question: Question): Question {
        const dto = this.dtoAdapter.adaptQuestion(question)
        return this.dtoAdapter.adaptQuestionDto(dto)
    }

    cloneSchedule(schedule: Schedule): Schedule {
        const dto = this.dtoAdapter.adaptSchedule(schedule)
        return this.dtoAdapter.adaptScheduleDto(dto)
    }

    cloneService(service: Service): Service {
        const dto = this.dtoAdapter.adaptService(service)
        return this.dtoAdapter.adaptServiceDto(dto)
    }

    cloneScheduleRule(rule: ScheduleRule): ScheduleRule {
        const dto = this.dtoAdapter.adaptScheduleRule(rule)
        return this.dtoAdapter.adaptScheduleRuleDto(dto)
    }

    cloneReason(reason: BookingReason): BookingReason {
        const dto = this.dtoAdapter.adaptBookingReason(reason)
        return this.dtoAdapter.adaptBookingReasonDto(dto)
    }

    cloneBooking(booking: Booking): Booking {
        const dto = this.dtoAdapter.adaptBooking(booking)
        return this.dtoAdapter.adaptBookingDto(dto)
    }

    cloneContact(contact: Contact): Contact {
        const dto = this.dtoAdapter.adaptContact(contact)
        return this.dtoAdapter.adaptContactDto(dto)
    }

    cloneCustomer(customer: Customer): Customer {
        const dto = this.dtoAdapter.adaptCustomer(customer)
        return this.dtoAdapter.adaptCustomerDto(dto)
    }

    cloneUser(user: User): User {
        const dto = this.dtoAdapter.adaptUser(user)
        return this.dtoAdapter.adaptUserDto(dto)
    }

    cloneEvent(event: Event): Event {
        const dto = this.dtoAdapter.adaptEvent(event)
        return this.dtoAdapter.adaptEventDto(dto)
    }
    cloneEmailMarketingConfiguration(integration: EmailMarketingConfiguration):
        EmailMarketingConfiguration {
        const dto = this.dtoAdapter.adaptEmailMarketingConfiguration(integration)
        return this.dtoAdapter.adaptEmailMarketingConfigurationDto(dto)
    }

    cloneTableCombination(tableCombination: TableCombination): TableCombination {
        const dto = this.dtoAdapter.adaptTableCombination(tableCombination)
        return this.dtoAdapter.adaptTableCombinationDto(dto)
    }

    cloneDiaryNote(diaryNote: DiaryNote): DiaryNote {
        const dto = this.dtoAdapter.adaptDiaryNote(diaryNote)
        return this.dtoAdapter.adaptDiaryNoteDto(dto)
    }

    cloneVenueClosure(closure: VenueClosure): VenueClosure {
        const dto = this.dtoAdapter.adaptVenueClosure(closure)
        return this.dtoAdapter.adaptVenueClosureDto(dto)
    }

    cloneVoucher(voucher: Voucher): Voucher {
        const dto = this.dtoAdapter.adaptVoucher(voucher)
        return this.dtoAdapter.adaptVoucherDto(dto)
    }
}
