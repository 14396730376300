export class Helper {

    static capitalize(string: string) {
        return string.replace(/\w\S*/g, (w) =>
            (w.replace(/^\w/, (c) => c.toUpperCase())))
    }

    static filterObjectWithoutKeys(object: any, excludedKeys: string[]) {
        return Object.fromEntries(Object.entries(object).filter(
            ([key]) => !excludedKeys.includes(key)))
    }

    static filterObjectWithKeys(object: any, includedKeys: string[]) {
        return Object.fromEntries(Object.entries(object).filter(
            ([key]) => includedKeys.includes(key)))
    }

    static relativePath(absolutePath: string) {
        const url = new URL(absolutePath, 'https://url.com')
        return url.pathname.substring(1)
    }

    static removeUndefinedValues(input: any) {
        if (input === undefined) {
            return undefined
        }

        if (Array.isArray(input)) {
            return (input as Array<any>).filter(element => element !== undefined)
        }

        Object.keys(input).forEach((key) => {
            if (input[key] === undefined) {
                delete input[key]
            }
            else if (input[key] === Object(input[key])) {
                input[key] = this.removeUndefinedValues(input[key])
            }
        })

        return input
    }

    /**
     * Name: returnArrayIndex
     *
     * Description: The returnArrayIndex() uses findIndex() method to return the index of the first
     * element in the array that satisfies the provided testing function. Otherwise, it returns -1,
     * indicating that no element passed the test.
     *
     * Params:
     * - array: any[],
     * - itemId: any
     *
     * Return: The index of the first element in the array that passes the test. Otherwise, -1.
     **/
    static returnArrayIndex(array: any[], itemId: any): number {
        const index = array.findIndex(key => {
            return key.id === itemId
        })

        return index
    }

    static rewriteEmptyValuesAsNull(input: any) {
        if (input === undefined) {
            return null
        }

        Object.keys(input).forEach((key) => {
            if (input[key] === '') {
                input[key] = null
            }
            else if (input[key] === Object(input[key])) {
                input[key] = this.rewriteEmptyValuesAsNull(input[key])
            }
        })

        return input
    }

    static rewriteUndefinedValuesAsNull(input: any) {
        if (input === undefined) {
            return null
        }

        Object.keys(input).forEach((key) => {
            if (input[key] === undefined) {
                input[key] = null
            }
            else if (input[key] === Object(input[key])) {
                input[key] = this.rewriteUndefinedValuesAsNull(input[key])
            }
        })

        return input
    }

    static startOfDay(day: Date = new Date()) {
        return new Date(
            day.getUTCFullYear(),
            day.getMonth(),
            day.getDate(),
            0,
            0,
            0
        )
    }

    static endOfDay(day: Date = new Date()) {
        return new Date(
            day.getFullYear(),
            day.getMonth(),
            day.getDate() + 1,
            0,
            0,
            -1
        )
    }

    static minusDays(d: Date, days: number) {
        const date = new Date(d)
        date.setDate(date.getDate() - days)
        return date
    }

    static plusDays(d: Date, days: number) {
        const date = new Date(d)
        date.setDate(date.getDate() + days)
        return date
    }

    static startOfWeek(d: Date = new Date()) {
        const date = new Date(d)
        date.setHours(0, 0, 0, 0)
        const day = date.getDay()
        const diff = date.getDate() - day + (day === 0 ? -6 : 1)

        return new Date(date.setDate(diff))
    }

    static endOfWeek(date: Date = new Date()) {
        const firstDay = this.startOfWeek(date)
        const lastDay = new Date(firstDay)
        lastDay.setDate(lastDay.getDate() + 6)

        return lastDay
    }

    static startOfMonth(date: Date = new Date()) {
        return new Date(date.getFullYear(), date.getMonth(), 1)
    }

    static endOfMonth(date: Date = new Date()) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0)
    }

    static startOfQuarter(date: Date = new Date()) {
        const quarter = Math.floor((date.getMonth() / 3))
        return new Date(date.getFullYear(), quarter * 3, 1)
    }

    static endOfQuarter(date: Date = new Date()) {
        const quarter = Math.floor((date.getMonth() / 3))
        return new Date(date.getFullYear(), quarter * 3 + 3, 0)
    }

    static startOfYear(date: Date = new Date()) {
        return new Date(date.getFullYear(), 0, 1)
    }

    static endOfYear(date: Date = new Date()) {
        return new Date(date.getFullYear(), 11, 31)
    }

    static minutesBetweenDates(start: Date | undefined, end: Date | undefined) {
        if (!start || !end) {
            return undefined
        }
        const difference = end.getTime() - start.getTime()
        return Math.round(difference / 60 / 1000)
    }

    static displayNameIsNumeric(displayName: string): boolean {
        return /^\d+$/.test(displayName.trim())
    }

    static getDisplayNameFromName(displayName: string, prefix: string = '', space: boolean = true): string {
        if (this.displayNameIsNumeric(displayName)) {
            return prefix + (space ? ' ' : '') + displayName
        }
        return displayName
    }

    static isIntegerInRange(
        number: number | null,
        rangeMinimum: number | null,
        rangeMaximum: number | null
    ): boolean {
        if (number === null) {
            return false
        }
        if (rangeMinimum === null) {
            return false
        }
        if (rangeMaximum === null) {
            return false
        }
        return number >= rangeMinimum && number <= rangeMaximum
    }

    static makeLocalISOFormattedDateString(date: Date): string {
        const offsetMs = date.getTimezoneOffset() * 60 * 1000
        const msLocal = date.getTime() - offsetMs
        const dateLocal = new Date(msLocal)
        const iso = dateLocal.toISOString()
        const [isoLocal] = iso.split('T')
        return isoLocal
    }

    static makeLocalISOFormattedDateTimeString(date: Date): string {
        const offsetMs = date.getTimezoneOffset() * 60 * 1000
        const msLocal = date.getTime() - offsetMs
        const dateLocal = new Date(msLocal)
        const iso = dateLocal.toISOString()
        return iso
    }

    static matchString(toMatch: string, possibleValues: string[]): boolean {
        if (possibleValues === undefined) {
            return false
        }
        let matchedStringReturn = false
        possibleValues.forEach(possibleValue => {
            if (toMatch.match(possibleValue)) {
                matchedStringReturn = true
            }
        })
        return matchedStringReturn
    }

    static isBetween(value: number, min: number, max: number) {
        return value >= min && value <= max
    }

    static isBetweenDate(value: Date, min: Date, max: Date) {
        return value >= min && value <= max
    }

    static startsBetweenDate(value: Date, min: Date, max: Date) {
        return value >= min && value < max
    }

    static copyStylesFromDocumentToWindow(document: Document, window: Window) {
        const links = Helper.getStyleSheetLinksFromDocument(document)
        links.forEach(link => {
            window.document.head.appendChild(link)
        })
    }

    static gbLocaleDateTimeStringToDate(dateString: string, timeZone: string): Date {
        const [dateStr, timeStr] = dateString.split(' ')
        const [day, month, year] = dateStr.split('/').map(Number)
        const [hours, minutes] = timeStr.split(':').map(Number)
        const localDate = new Date(Date.UTC(year, month - 1, day, hours, minutes))
        const options: Intl.DateTimeFormatOptions = {
            timeZone: timeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        }
        const formatter = new Intl.DateTimeFormat('en-GB', options)
        const parts = formatter.formatToParts(localDate)
        const formattedDateStr = `${parts[4].value}-${parts[2].value}-${parts[0].value}T${parts[6].value}:${parts[8].value}:00`
        const gbDate = new Date(formattedDateStr)
        const timeZoneOffsetAtDate = gbDate.getTimezoneOffset() * 60 * 1000
        return new Date(gbDate.getTime() + timeZoneOffsetAtDate)
    }

    private static getStyleSheetLinksFromDocument(document: Document): HTMLLinkElement[] {
        const links: HTMLLinkElement[] = []
        document.querySelectorAll('link')
            .forEach(htmlElement => {
                if (htmlElement.rel === 'stylesheet') {
                    const styleSheetElement = document.createElement('link')
                    const absoluteUrl = new URL(htmlElement.href).href
                    styleSheetElement.rel = 'stylesheet'
                    styleSheetElement.type = 'text/css'
                    styleSheetElement.href = absoluteUrl
                    links.push(styleSheetElement)
                }
            })
        return links
    }

    static compareArrays(arrayA: any[], arrayB: any[]): boolean {
        if (arrayA.length !== arrayB.length) {
            return false
        }
        return arrayA.every((value, index) => value === arrayB[index])
    }
}
