import { EmailMarketingList } from '@app/domain/integration/email-marketing/EmailMarketingList'
import {
    EmailMarketingListAssociationType,
    EmailMarketingListField,
} from '@app/domain/integration/email-marketing/EmailMarketingListField'
import { EmailMarketingSource } from '@app/domain/integration/email-marketing/EmailMarketingSource'
import { EmailMarketingTag } from '@app/domain/integration/email-marketing/EmailMarketingTag'

export enum EmailMarketingStatus {
    SUBSCRIBED = 'SUBSCRIBED',
    UNSUBSCRIBED = 'UNSUBSCRIBED',
    PENDING = 'PENDING'
}

export class EmailMarketingConfiguration {
    constructor(
        public id: string,
        public isEnabled: boolean,
        public source: EmailMarketingSource,
        public apiKey: string,
        public list: EmailMarketingList,
        public fields: EmailMarketingListField[],
        public tags: EmailMarketingTag[] | null,
        public status: EmailMarketingStatus
    ) { }

    public getFirstNameField(): EmailMarketingListField | null {
        return this.getFieldByAssociation(EmailMarketingListAssociationType.FIRSTNAME)
    }

    public getLastNameField(): EmailMarketingListField | null {
        return this.getFieldByAssociation(EmailMarketingListAssociationType.LASTNAME)
    }

    public getEmailAddressField(): EmailMarketingListField | null {
        return this.getFieldByAssociation(EmailMarketingListAssociationType.EMAIL_ADDRESS)
    }

    public getPhoneNumberField(): EmailMarketingListField | null {
        return this.getFieldByAssociation(EmailMarketingListAssociationType.PHONE_NUMBER)
    }

    private getFieldByAssociation(association: EmailMarketingListAssociationType):
        EmailMarketingListField | null {
        for (const field of this.fields) {
            if (field.association === association) {
                return field
            }
        }
        return null
    }

    public getTagsString(): string | null {
        return this.tags?.map(tag => tag.name).join(', ') ?? null
    }

}
