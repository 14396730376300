import { AddChargeComponent } from './components/add-charge/add-charge.component'
import {
    AddNewCustomerToBookingComponent,
} from './components/add-customer/add-new-customer-to-booking.component'
import { AreaFloorComponent } from './components/area-floor/area-floor.component'
import { AreaNamePipe } from '@app/features/shared/pipes/area-name.pipe'
import { BannerComponent } from './components/banner/banner.component'
import { BookingComponent } from '@app/features/shared/components/booking/booking.component'
import {
    BookingDetailsComponent,
} from '@app/features/shared/components/booking-details/booking-details.component'
import {
    BookingHistoryComponent,
} from '@app/features/shared/components/booking-history/booking-history.component'
import {
    BookingListItemComponent,
} from './components/booking-list-item/booking-list-item.component'
import { BookingPaymentsComponent } from './components/booking-payments/booking-payments.component'
import {
    BookingSourceBadgeComponent,
} from '@app/features/shared/components/booking-source-badge/booking-source-badge.component'
import { BookingSourceNamePipe } from '@app/features/shared/pipes/booking-source-name.pipe'
import {
    BookingStatusBadgeComponent,
} from '@app/features/shared/components/booking-status-badge/booking-status-badge.component'
import { BookingStatusNamePipe } from './pipes/booking-status-name.pipe'
import { BookingsListComponent } from './components/bookings-list/bookings-list.component'
import { CapitaliseOnBlurDirective } from './directives/capitalise-on-blur.directive'
import { CommonDateRangePipe } from './pipes/common-date-range.pipe'
import {
    CommonModule,
    CurrencyPipe,
    DatePipe,
    I18nPluralPipe,
    NgOptimizedImage,
} from '@angular/common'
import { ConfirmUndoCancellationComponent } from './components/confirm-undo-cancellation/confirm-undo-cancellation.component'
import {
    ContactDetailsComponent,
} from '@app/features/shared/components/contact-details/contact-details.component'
import { ContactFormComponent } from './components/contact-form/contact-form.component'
import { CurrencyUnitPipe } from './pipes/currency-unit.pipe'
import {
    CustomerBookingListComponent,
} from './components/customer-booking-list/customer-booking-list.component'
import { CustomerComponent } from './components/customer/customer.component'
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component'
import { DateArrayPipe } from '@app/features/shared/pipes/date-array.pipe'
import {
    DateComponentDescriptionPipe,
} from '@app/features/shared/pipes/date-component-description.pipe'
import {
    DateRangePickerComponent,
} from '@app/features/shared/components/date-range-picker/date-range-picker.component'
import { DateTimePipe } from './pipes/date-time.pipe'
import { DayNamePipe } from '@app/features/shared/pipes/day-name.pipe'
import { DayPickerComponent } from '@app/features/shared/components/day-picker/day-picker.component'
import { DragMovementDirective } from './directives/drag-movement.directive'
import { DurationPipe } from '@app/features/shared/pipes/duration.pipe'
import { EditChargeFormComponent } from './components/edit-charge-form/edit-charge-form.component'
import { EditableIconDirective } from './directives/editable-icon.directive'
import {
    ElementChoiceSelectComponent,
} from '@app/features/shared/components/element-choice-select/element-choice-select.component'
import { EventRecurrenceDescriptionPipe } from './pipes/event-recurrence-description.pipe'
import { FadeInDirective } from './directives/fade-in.directive'
import { FadeOnChangeDirective } from './directives/fade-on-change.directive'
import { FeedbackReviewSourcePipe } from './pipes/feedback-review-source.pipe'
import { FloorTableComponent } from './components/floor-table/floor-table.component'
import { FloorTablesComponent } from './components/floor-tables/floor-tables.component'
import { FormListItemComponent } from './components/form-list-item/form-list-item.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
    GBLocaleDateParserFormatter,
} from './components/date-range-picker/g-b-locale-date-parser-formatter.service'
import { GroupedScalePipe } from '@app/features/shared/pipes/grouped-scale.pipe'
import { HTMLComponent } from './components/html/html.component'
import { HighlightCodePipe } from './pipes/highlight-code.pipe'
import { IfLoggedInDirective } from '@app/features/shared/directives/if-logged-in.directive'
import { InputComponent } from './components/input/input.component'
import { ListItemComponent } from '@app/features/shared/components/list-item/list-item.component'
import {
    LoadBookingComponent,
} from '@app/features/shared/components/load-booking/load-booking.component'
import { LoadingComponent } from '@app/features/shared/components/loading/loading.component'
import { LogoComponent } from './components/logo/logo.component'
import { ModalComponent } from './components/modal/modal.component'
import { NgModule } from '@angular/core'
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgbDatePipe } from '@app/features/shared/pipes/ngb-date.pipe'
import { NgbTimePipe } from './pipes/ngb-time.pipe'
import { NgxPaginationModule } from 'ngx-pagination'
import { NotificationCountPipe } from '@app/features/shared/pipes/notification-count.pipe'
import { NumberInputComponent } from './components/number-input/number-input.component'
import { NumberRangePipe } from '@app/features/shared/pipes/number-range.pipe'
import { OffsetDatePipe } from './pipes/offset-date.pipe'
import { OrdinalDatePipe } from './pipes/ordinal-date.pipe'
import { PageContentComponent } from '@app/features/shared/components/page/page-content.component'
import {
    PagedBookingListComponent,
} from '../bookings/components/search-bookings/paged-booking-list/paged-booking-list.component'
import {
    PartySizeBadgeComponent,
} from '@app/features/shared/components/party-size-badge/party-size-badge.component'
import { PathPipe } from './pipes/path.pipe'
import { PhoneNumberPipe } from './pipes/phone-number.pipe'
import {
    PrintBookingDiaryComponent,
} from './components/print-booking-diary/print-booking-diary.component'
import { QuestionTypeNamePipe } from './pipes/question-type-name.pipe'
import { RatingNamePipe } from './pipes/rating-name.pipe'
import { RatingStatusDirective } from './directives/rating-status.directive'
import { ReasonDateRangesPipe } from '@app/features/shared/pipes/reason-date-ranges.pipe'
import { ReasonNamePipe } from '@app/features/shared/pipes/reason-name.pipe'
import { ReasonSchedulePipe } from '@app/features/shared/pipes/reason-schedule.pipe'
import { RelativeDatePipe } from '@app/features/shared/pipes/relative-date.pipe'
import { RelativeTimeScalePipe } from '@app/features/shared/pipes/relative-time-scale.pipe'
import {
    RequestedBookingDetailsComponent,
} from '@app/features/shared/components/requested-booking-details/requested-booking-details.component'
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'
import { ScalePipe } from '@app/features/shared/pipes/scale.pipe'
import { ScheduleDateRangePipe } from './pipes/schedule-date-range.pipe'
import { ScheduleRulesPipe } from '@app/features/shared/pipes/schedule-rules.pipe'
import {
    ScreenSizeDetectorComponent,
} from './components/size-detector/screen-size-detector.component'
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component'
import { StringMaskPipe } from '@app/features/shared/pipes/string-mask.pipe'
import {
    SvgCursorMovementDirective,
} from '@app/features/shared/directives/svg-cursor-movement.directive'
import { TableNamePipe } from './pipes/table-name.pipe'
import { TextareaComponent } from './components/textarea/textarea.component'
import { ThumborPipe } from './pipes/thumbor.pipe'
import { UserPermissionDirective } from '@app/features/shared/directives/user-permission.directive'
import { UserRoleNamePipe } from './pipes/user-role-name.pipe'
import { UserVenueNamesPipe } from '@app/features/shared/pipes/venue-name.pipe'
import { VenueSelectionComponent } from './components/venue-selection/venue-selection.component'
import { WithLoadingPipe } from '@app/features/shared/pipes/with-loading.pipe'

@NgModule({
    declarations: [
        AreaFloorComponent,
        AreaNamePipe,
        BannerComponent,
        BookingComponent,
        BookingDetailsComponent,
        BookingListItemComponent,
        BookingPaymentsComponent,
        BookingsListComponent,
        BookingSourceNamePipe,
        BookingStatusNamePipe,
        CommonDateRangePipe,
        ContactDetailsComponent,
        ContactFormComponent,
        CurrencyUnitPipe,
        CustomerBookingListComponent,
        CustomerComponent,
        CustomerDetailsComponent,
        CustomerDetailsComponent,
        DateComponentDescriptionPipe,
        DateRangePickerComponent,
        AddNewCustomerToBookingComponent,
        DateTimePipe,
        DayNamePipe,
        DayPickerComponent,
        DragMovementDirective,
        DurationPipe,
        EditableIconDirective,
        ElementChoiceSelectComponent,
        EventRecurrenceDescriptionPipe,
        FadeInDirective,
        FadeOnChangeDirective,
        FeedbackReviewSourcePipe,
        FloorTableComponent,
        FloorTablesComponent,
        FormListItemComponent,
        GroupedScalePipe,
        HighlightCodePipe,
        HTMLComponent,
        IfLoggedInDirective,
        InputComponent,
        ListItemComponent,
        LoadBookingComponent,
        LoadingComponent,
        LogoComponent,
        ModalComponent,
        NgbDatePipe,
        NgbTimePipe,
        NotificationCountPipe,
        NumberInputComponent,
        NumberRangePipe,
        OffsetDatePipe,
        OrdinalDatePipe,
        PageContentComponent,
        PagedBookingListComponent,
        PathPipe,
        PhoneNumberPipe,
        PrintBookingDiaryComponent,
        QuestionTypeNamePipe,
        RatingNamePipe,
        RatingStatusDirective,
        ReasonDateRangesPipe,
        ReasonNamePipe,
        ReasonSchedulePipe,
        RelativeDatePipe,
        RelativeTimeScalePipe,
        RequestedBookingDetailsComponent,
        ScalePipe,
        ScheduleDateRangePipe,
        ScheduleRulesPipe,
        ScreenSizeDetectorComponent,
        SpinnerOverlayComponent,
        StringMaskPipe,
        SvgCursorMovementDirective,
        TableNamePipe,
        TextareaComponent,
        ThumborPipe,
        UserPermissionDirective,
        UserRoleNamePipe,
        UserVenueNamesPipe,
        VenueSelectionComponent,
        WithLoadingPipe,
        AddChargeComponent,
        ConfirmUndoCancellationComponent,
        EditChargeFormComponent,
    ],
    imports: [
        BookingHistoryComponent,
        BookingSourceBadgeComponent,
        BookingStatusBadgeComponent,
        CapitaliseOnBlurDirective,
        CommonModule,
        FormsModule,
        NgbModule,
        NgOptimizedImage,
        NgxPaginationModule,
        PartySizeBadgeComponent,
        ReactiveFormsModule,
        RxReactiveFormsModule,
    ],
    exports: [
        AreaFloorComponent,
        AreaNamePipe,
        BannerComponent,
        BookingComponent,
        BookingListItemComponent,
        BookingsListComponent,
        BookingSourceNamePipe,
        BookingStatusNamePipe,
        CommonDateRangePipe,
        ContactFormComponent,
        CurrencyUnitPipe,
        DateComponentDescriptionPipe,
        DateRangePickerComponent,
        DateTimePipe,
        DayNamePipe,
        DayPickerComponent,
        DragMovementDirective,
        DurationPipe,
        EditableIconDirective,
        EditableIconDirective,
        ElementChoiceSelectComponent,
        EventRecurrenceDescriptionPipe,
        FadeInDirective,
        FadeOnChangeDirective,
        FeedbackReviewSourcePipe,
        FloorTableComponent,
        FloorTablesComponent,
        FormListItemComponent,
        GroupedScalePipe,
        HighlightCodePipe,
        HTMLComponent,
        IfLoggedInDirective,
        IfLoggedInDirective,
        InputComponent,
        ListItemComponent,
        LoadingComponent,
        LogoComponent,
        ModalComponent,
        NgbDatePipe,
        NgbTimePipe,
        NotificationCountPipe,
        NumberInputComponent,
        NumberRangePipe,
        OffsetDatePipe,
        OrdinalDatePipe,
        PageContentComponent,
        PagedBookingListComponent,
        PathPipe,
        PhoneNumberPipe,
        PrintBookingDiaryComponent,
        QuestionTypeNamePipe,
        RatingNamePipe,
        RatingStatusDirective,
        ReasonDateRangesPipe,
        ReasonNamePipe,
        ReasonSchedulePipe,
        RelativeDatePipe,
        RelativeTimeScalePipe,
        ScalePipe,
        ScheduleDateRangePipe,
        ScheduleRulesPipe,
        ScreenSizeDetectorComponent,
        SpinnerOverlayComponent,
        StringMaskPipe,
        SvgCursorMovementDirective,
        TableNamePipe,
        TextareaComponent,
        ThumborPipe,
        UserPermissionDirective,
        UserRoleNamePipe,
        UserVenueNamesPipe,
        VenueSelectionComponent,
        WithLoadingPipe,
    ],
    providers: [
        CurrencyPipe,
        CurrencyUnitPipe,
        DateArrayPipe,
        DateComponentDescriptionPipe,
        DatePipe,
        DateTimePipe,
        DayNamePipe,
        DurationPipe,
        GroupedScalePipe,
        I18nPluralPipe,
        NgbDatePipe,
        OrdinalDatePipe,
        {
            provide: NgbDateParserFormatter,
            useClass: GBLocaleDateParserFormatter,
        },
        ReasonDateRangesPipe,
        ReasonSchedulePipe,
        RelativeDatePipe,
        ScalePipe,
        ScheduleDateRangePipe,
        ScheduleRulesPipe,
        TableNamePipe,
    ],
})
export class SharedModule { }
