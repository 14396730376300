import { SKU } from './SKU'

export abstract class Product {
    protected constructor(
        public id: string,
        public displayName: string,
        public description: string | null,
        public skus: SKU[],
        public dateCreated: Date,
        public dateUpdated: Date
    ) { }
}
