import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingChangedMetadata } from '@app/features/shared/components/booking/booking.component'
import { BookingService } from '@services/booking.service'
import { Business } from '@app/domain/Business'
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { Contact } from '@app/domain/Contact'
import {
    ContactDetailsViewModel,
    ContactDetailsViewModelDelegate,
} from './contact-details-view-model'
import { ContextService } from '@services/context.service'
import { Customer } from '../../../../domain/Customer'
import { InputComponent, InputType } from '@app/features/shared/components/input/input.component'
import { ModalComponent } from '@app/features/shared/components/modal/modal.component'
import { ModelCloningService } from '@services/model-cloning.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
    Observable,
    Subject,
    finalize,
    mergeMap,
    takeUntil,
    tap,
    BehaviorSubject,
    of,
    debounceTime, distinctUntilChanged
} from 'rxjs'
import { ToastBuilder } from '@app/domain/ToastBuilder'
import { ToastService } from '@services/toast.service'
import { ToastType } from '@app/domain/Toast'
import { Venue } from '@app/domain/Venue'
import { FormControl } from '@angular/forms'
import { CustomerService } from '../../../../services/customer.service'
import { AddNewCustomerToBookingComponent } from '../add-customer/add-new-customer-to-booking.component'

@Component({
    selector: 'app-contact-details',
    templateUrl: './contact-details.component.html',
})
export class ContactDetailsComponent implements
    OnInit,
    OnDestroy,
    ContactDetailsViewModelDelegate {
    @Input() business!: Business
    @Input() venue!: Venue
    area!: Area
    @Input() booking!: Booking
    @Output() bookingChanged = new EventEmitter<[Booking, Partial<BookingChangedMetadata>]>()
    @Output() customerSelected = new EventEmitter<Customer>()
    agreedToMarketingMapping = {
        true: 'Yes',
        false: 'No',
    }
    viewModel!: ContactDetailsViewModel
    showShouldSendFeedbackRequest = false
    showCustomerSearchInput = false
    customerSearchFormControl = new FormControl()
    customerSearch$!: Observable<Customer[]>

    @ViewChild('customerName') customerName!: InputComponent
    @ViewChild('emailAddress') emailAddress!: InputComponent
    @ViewChild('phoneNumber') phoneNumber!: InputComponent
    @ViewChild('sendFeedbackRequest') sendFeedbackRequest!: Input
    private onDestroy = new Subject<void>()

    constructor(
        private bookingService: BookingService,
        private contextService: ContextService,
        private customerService: CustomerService,
        private modalService: NgbModal,
        private modelCloningService: ModelCloningService,
        private toastService: ToastService
    ) { }

    ngOnInit() {
        this.showShouldSendFeedbackRequest = this.venue.isSendFeedbackRequestEnabled()
        this.area = this.venue.areaBookingIsIn(this.booking)!
        this.viewModel = new ContactDetailsViewModel(
            this,
            this.booking.sendFeedbackRequest
        )
        this.bookingChanged
            .pipe(
                takeUntil(this.onDestroy)
            )
            .subscribe(([booking, _]) => {
                this.booking = booking
                this.area = this.venue.areaBookingIsIn(this.booking)!
                this.viewModel = new ContactDetailsViewModel(
                    this,
                    this.booking.sendFeedbackRequest
                )
            })
        this.customerSearch$ = this.customerSearchFormControl.valueChanges
            .pipe(
                takeUntil(this.onDestroy),
                debounceTime(300),
                distinctUntilChanged(),
                mergeMap(searchTerm => this.searchCustomersBySearchTerm(searchTerm))
            )
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    updateEmailAddress(emailAddress: string | null): void {
        const updatedContact = this.modelCloningService.cloneContact(this.booking.contact)
        updatedContact.updateEmailAddress(emailAddress)
        this.updateBookingContact(updatedContact)
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.emailAddress.finishInputUpdate()
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        'Customer Email Address updated',
                        'Customer Updated'
                    )
                },
                error: _ => {
                    this.emailAddress.cancelInputUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this customer\'s email address. Please try again.',
                        'Customer Update Error'
                    )
                },
            })
    }

    updateName(firstName: string | null, lastName: string | null): void {
        const updatedContact = this.modelCloningService.cloneContact(this.booking.contact)
        updatedContact.updateFirstName(firstName)
        updatedContact.updateLastName(lastName)
        this.updateBookingContact(updatedContact)
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.customerName.finishInputUpdate()
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        'Customer Name updated',
                        'Customer Updated'
                    )
                },
                error: _ => {
                    this.customerName.cancelInputUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this customer\'s name. Please try again.',
                        'Customer Update Error'
                    )
                },
            })
    }

    updatePhoneNumber(phoneNumber: string | null): void {
        const updatedContact = this.modelCloningService.cloneContact(this.booking.contact)
        updatedContact.updatePhoneNumber(phoneNumber)
        this.updateBookingContact(updatedContact)
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.phoneNumber.finishInputUpdate()
                    this.bookingChanged.emit([booking, {}])
                    this.showSuccess(
                        'Customer Phone Number updated',
                        'Customer Updated'
                    )
                },
                error: _ => {
                    this.phoneNumber.cancelInputUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this customer\'s phone number. Please try again.',
                        'Customer Update Error'
                    )
                },
            })
    }

    updateSendFeedbackRequest(sendFeedbackRequest: boolean): void {
        if (this.booking.sendFeedbackRequest === sendFeedbackRequest) {
            return
        }

        this.viewModel.isEditingBooking = true
        const updatedBooking = this.modelCloningService.cloneBooking(this.booking)
        updatedBooking.sendFeedbackRequest = sendFeedbackRequest
        this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.viewModel.shouldSendFeedbackRequest = this.booking.sendFeedbackRequest
                    this.bookingChanged.emit([booking, { changeHasFinishedInteraction: false }])
                    this.showSuccess(
                        'Feedback request updated',
                        'Booking Updated'
                    )
                },
                error: _ => {
                    //TODO Track the error
                    this.viewModel.shouldSendFeedbackRequest = this.booking.sendFeedbackRequest
                    this.showError(
                        'Couldn\'t update this booking\'s feedback request. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    attachBookingToCustomer(customer: Customer) {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return
        }
        this.viewModel.isEditingBooking = true
        this.bookingService.attachCustomerToBooking(
            organisation.id,
            this.business.id,
            this.venue.id,
            this.booking.id,
            customer.id
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.bookingChanged.emit([booking, { changeHasFinishedInteraction: false }])
                    this.showSuccess(
                        'Customer linked to booking',
                        'Booking Updated'
                    )
                },
                error: _ => {
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t link this customer to the booking. Please try again.',
                        'Error'
                    )
                },
            })
    }

    detachBookingFromCustomer() {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return
        }
        this.viewModel.isEditingBooking = true
        this.bookingService.detachCustomerFromBooking(
            organisation.id,
            this.business.id,
            this.venue.id,
            this.booking.id
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
            .subscribe({
                next: booking => {
                    this.booking = booking
                    this.bookingChanged.emit([booking, { changeHasFinishedInteraction: false }])
                    this.showSuccess(
                        'Customer unlinked from booking',
                        'Booking Updated'
                    )
                },
                error: _ => {
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t unlink this customer from the booking. Please try again.',
                        'Error'
                    )
                },
            })
    }

    resendBookingConfirmationRequested(modalContent: TemplateRef<any>) {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return
        }
        const business = this.contextService.getBusiness()
        if (business === null) {
            return
        }
        const venue = this.contextService.getVenue()
        if (venue === null) {
            return
        }
        const modal = this.modalService.open(ModalComponent)
        const component = modal.componentInstance as ModalComponent
        component.title = 'Resend Booking Confirmation'
        component.contentTemplate = modalContent
        component.actionType = 'confirm'
        component.actionSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    return this.bookingService.bookingConfirmationResendRequested(
                        organisation,
                        business,
                        venue,
                        this.booking
                    )
                })
            )
            .subscribe({
                next: _ => {
                    modal.close()
                    this.showSuccess(
                        'Booking Confirmation email resent',
                        'Booking Confirmation Resent'
                    )
                },
                error: _ => {
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t resend this booking\'s email confirmation. Please try again.',
                        'Booking Confirmation Resend Error'
                    )
                },
            })
    }

    createCustomer() {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return
        }
        const modal = this.modalService.open(AddNewCustomerToBookingComponent)
        const component = modal.componentInstance as AddNewCustomerToBookingComponent
        component.organisation = organisation
        component.business = this.business
        component.venue = this.venue
        component.booking = this.booking
        modal.closed
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (booking) => {
                    this.bookingChanged.emit([booking, { changeHasFinishedInteraction: false }])
                    this.showSuccess(
                        'Customer added to booking',
                        'Booking Updated'
                    )
                },
                error: (error) => {
                    console.error(error)
                    this.showError(
                        'Couldn\'t add this customer to the booking. Please try again.',
                        'Error'
                    )
                },
            })
    }

    private searchCustomersBySearchTerm(searchTerm: string): Observable<Customer[]> {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return of([])
        }
        if (searchTerm.length < 3) {
            return of([])
        }
        return this.customerService.searchCustomersBySearchTerm(
            organisation.id,
            this.business.id,
            this.venue.id,
            searchTerm
        )
    }

    private updateBookingContact(updatedContact: Contact): Observable<Booking> {
        this.viewModel.isEditingBooking = true
        const updatedBooking = this.modelCloningService.cloneBooking(this.booking)
        updatedBooking.updateContact(updatedContact)
        return this.bookingService.patchBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            this.booking,
            updatedBooking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.viewModel.isEditingBooking = false)
            )
    }

    private showError(message: string, title?: string) {
        this.showToast(ToastType.Danger, message, title)
    }

    private showSuccess(message: string, title?: string) {
        this.showToast(ToastType.Success, message, title)
    }

    private showToast(toastType: ToastType, message: string, title?: string) {
        let  toastBuilder = new ToastBuilder(message)
            .withType(toastType)

        if (title){
            toastBuilder = toastBuilder.withHeader(title)
        }

        this.toastService.show(toastBuilder.build())
    }

    protected readonly InputType = InputType
}
