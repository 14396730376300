import { Voucher } from './Voucher'

export class ProductLibrary {
    constructor(
        public id: string,
        public venueId: string,
        public vouchers: Voucher[]
    ) { }

    addVoucher(voucher: Voucher): boolean {
        if (this.getVoucher(voucher.id)) {
            return false
        }
        this.vouchers.push(voucher)
        return true
    }

    getFirstOnSaleVoucher(): Voucher | null {
        return this.vouchers.find((voucher) => {
            return voucher.hasSKUsOnSale()
        }) || null
    }

    updateVoucher(voucher: Voucher): boolean {
        const index = this.vouchers.findIndex(v => v.id === voucher.id)
        if (index === -1) {
            return false
        }
        this.vouchers[index] = voucher
        return true
    }

    getVoucher(id: string): Voucher | null {
        return this.voucherWithId(id)
    }

    private voucherWithId(id: string): Voucher | null {
        return this.vouchers.find(v => v.id === id) || null
    }
}
