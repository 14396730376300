import { Customer } from '@app/domain/Customer'
import { CustomerDTO } from '@services/DTO/CustomerDTO'
import { DTOAdapter } from './DTOAdapter'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'
import { compare } from 'fast-json-patch'
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root',
})
export class CustomerService {

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) { }

    searchCustomers(
        organisationId: string,
        businessId: string,
        venueId: string,
        firstName: string | null,
        lastName: string | null,
        emailAddress: string | null,
        phoneNumber: string | null
    ): Observable<Customer[]> {
        const path =
            `/organisation/${organisationId}` +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            '/customer/search'
        const url = new URL(path, environment.apiBaseURL)
        let params = new HttpParams()
        if (firstName) {
            params = params.set('firstName', firstName)
        }
        if (lastName) {
            params = params.set('lastName', lastName)
        }
        if (emailAddress) {
            params = params.set('emailAddress', emailAddress)
        }
        if (phoneNumber) {
            params = params.set('phoneNumber', phoneNumber)
        }
        return this.http.get<CustomerDTO[]>(url.toString(), { params })
            .pipe(
                map(dtos => dtos.map(dto => this.dtoAdapter.adaptCustomerDto(dto)))
            )
    }

    searchCustomersBySearchTerm(
        organisationId: string,
        businessId: string,
        venueId: string,
        term: string
    ): Observable<Customer[]> {
        const path =
            `/organisation/${organisationId}` +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/customer/search/${term}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.get<CustomerDTO[]>(url.toString())
            .pipe(
                map(dtos => dtos.map(dto => this.dtoAdapter.adaptCustomerDto(dto)))
            )
    }

    updateCustomer(
        organisationId: string,
        businessId: string,
        venueId: string,
        customer: Customer,
        updatedCustomer: Customer
    ): Observable<Customer> {
        const path = `/organisation/${organisationId}` +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/customer/${customer.id}`
        const url = new URL(path, environment.apiBaseURL)
        const customerDTO = this.dtoAdapter.adaptCustomer(customer)
        const updatedCustomerDTO = this.dtoAdapter.adaptCustomer(updatedCustomer)
        const body = compare(customerDTO, updatedCustomerDTO)
        return this.http.patch<CustomerDTO>(url.toString(), body)
            .pipe(
                map(dto => this.dtoAdapter.adaptCustomerDto(dto))
            )
    }
}
