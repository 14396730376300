import { Answer } from '../../../domain/Answer'
import { BookingSlot } from '../../../domain/BookingSlot'
import { BookingStatusType } from '../../../domain/BookingStatus'
import { Contact } from '../../../domain/Contact'
import { HasBookingStatus } from '../../bookings/pipes/booking-status-filter.pipe'

export class BookingRequest implements HasBookingStatus {

    dateVoided: Date | null = null

    constructor(
        public bookingSlot: BookingSlot,
        public contact: Contact,
        public partySize: number,
        public notes: string | null,
        public agreedToMarketing: boolean,
        public agreedToTerms: boolean,
        public paymentMethodId: string | null,
        public reasonId: string | null,
        public eventId: string | null,
        public answers: Answer[],
        public depositPaymentIntentId: string | null,
        public requiresWheelchairAccess: boolean,
        public requiresDogFriendly: boolean,
        public dateCreated: Date
    ) { }

    get statusType() {
        return BookingStatusType.Waiting
    }
}
